const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  body: {
    "& tr": {
      borderBottom: "solid 1px red",
    },
  },
  list: {
    backgroundColor: theme.palette.lightGrey.main,
    margin: theme.spacing(5),
    borderRadius: 5,
    listStyle: "none",
  },
  addCodeButton: {
    backgroundColor: theme.palette.orange.main,
    borderRadius: 12,
    color: "#ffffff",
    marginBottom: 32,
    marginTop: 12,
    fontWeight: 900,
    fontSize: 16,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.orange.dark,
    },
  },
  tourDeactivated: {
    textAlign: "center",
    borderRadius: 12,
    color: "red",
    marginBottom: 12,
    marginTop: 12,
    fontWeight: 900,
    fontSize: 16,
    textTransform: "none",
  },
  searchInput: {
    backgroundColor: "white",
    borderRadius: 12,
    marginBottom: 32,
    width: "40%",
  },
  table: {
    backgroundColor: theme.palette.primary.main,
  },
  tableCellHeader: {
    color: "#ffffff",
    fontWeight: 600,
    fontSize: 14,
    borderBottom: "solid 1px #768FDD88",
    backgroundColor: theme.palette.darkGrey.main,
  },
  tableCell: {
    color: "#ffffff",
    borderBottom: "solid 1px #768FDD88",
  },
  alreadySentMailButton: {
    color: "#ffffff",
    backgroundColor: theme.palette.green.main,
    borderRadius: 12,
    textTransform: "none",
  },
  sendMailButton: {
    color: "#ffffff",
    backgroundColor: theme.palette.orange.main,
    borderRadius: 12,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.orange.dark,
    },
  },
  disabledSendMailButton: {
    color: "#ffffffff",
    backgroundColor: "#F1DBC5",
    borderRadius: 12,
    textTransform: "none",
  },
  buttonDisabled: {
    backgroundColor: "#8e999d",
    cursor: "default",
    "&:hover": {
      backgroundColor: "#8e999d",
    },
  },
  switchVisibility: {
    display: "inline",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
});

export default styles;
