import Axios from "axios";
import { apiHost } from "../constants/ApiConstants";
// import JwtUtils from "../utils/jwtUtils";
import apiClient from "./ApiClient";

export default class ApiUser {
  static async signup(
    firstName,
    lastName,
    email,
    company,
    phoneNumber,
    password,
    language
  ) {
    return await apiClient
      .post(`${apiHost}/user/signup`, {
        firstName: firstName,
        lastName: lastName,
        email: email,
        company: company,
        phoneNumber: phoneNumber,
        password,
        language,
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = {
          status: error.response?.status,
          data: error.response?.data,
        };

        return errorLog;
      });
  }
  static async signin(email, password) {
    return await apiClient
      .post(`${apiHost}/user/signin`, {
        email,
        password,
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response?.status,
            data: error.response?.data,
          };
        }

        return errorLog;
      });
  }

  static async logout() {
    return await apiClient
      .post(`${apiHost}/user/logout`)
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response?.status,
            data: error.response?.data,
          };
        }

        return errorLog;
      });
  }

  static async isAdmin() {
    return await apiClient
      .get(`${apiHost}/user/isAdmin`, {})
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        console.log("error ", error);
        let errorLog = {
          status: error?.response?.status,
          data: error?.response?.data,
        };

        return errorLog;
      });
  }

  static async getAccount() {
    return await apiClient
      .get(`${apiHost}/user/profile`, {})
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response?.status,
            data: error.response?.data,
          };
        }

        return errorLog;
      });
  }

  static async getHubspotUserToken() {
    return await apiClient
      .get(`${apiHost}/user/hubspotUserToken`, {})
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response?.status,
            data: error.response?.data,
          };
        }

        return errorLog;
      });
  }

  static async getAnalytics() {
    return await apiClient
      .get(`${apiHost}/user/analytics`, {})
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response?.status,
            data: error.response?.data,
          };
        }

        return errorLog;
      });
  }

  static async getUserAccount(accountId) {
    return await apiClient
      .get(`${apiHost}/user/account/${accountId}`)
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response?.status,
            data: error.response?.data,
          };
        }

        return errorLog;
      });
  }

  static async updateAccount(
    firstName,
    lastName,
    company,
    address,
    siret,
    phoneNumber,
    limitSessionPerMonth
  ) {
    return await apiClient
      .post(
        `${apiHost}/user/profile`,
        {
          firstName: firstName,
          lastName: lastName,
          company: company,
          address: address,
          siret: siret,
          phoneNumber: phoneNumber,
          limitSessionPerMonth: limitSessionPerMonth,
        },
        {}
      )
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response?.status,
            data: error.response?.data,
          };
        }

        return errorLog;
      });
  }

  static async verifyAccount(verifyToken) {
    return await apiClient
      .post(
        `${apiHost}/user/verifyToken`,
        {
          verifyToken: verifyToken,
        },
        {}
      )
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response?.status,
            data: error.response?.data,
          };
        }

        return errorLog;
      });
  }

  static async requestPasswordReset(email) {
    return await apiClient
      .post(`${apiHost}/user/requestNewPassword`, {
        email: email,
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response?.status,
            data: error.response?.data,
          };
        }

        return errorLog;
      });
  }

  static async resetPassword(token, newPassword) {
    return await apiClient
      .post(`${apiHost}/user/resetPassword`, {
        token: token,
        newPassword: newPassword,
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response?.status,
            data: error.response?.data,
          };
        }

        return errorLog;
      });
  }

  static async setDisabledTours(toursToDisable) {
    return await apiClient
      .post(`${apiHost}/user/setDisabledTours`, {
        toursToDisable: toursToDisable,
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response?.status,
            data: error.response?.data,
          };
        }

        return errorLog;
      });
  }

  static async needToDisableSomeTours() {
    return await apiClient
      .get(`${apiHost}/user/needToDisableSomeTours`)
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response?.status,
            data: error.response?.data,
          };
        }

        return errorLog;
      });
  }
}
