import React, { Component } from "react";
import Alert from "@material-ui/lab/Alert";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardMedia,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import { apiHost } from "../../../constants/ApiConstants";
import { useTranslation } from "react-i18next";
import HubspotChat from "../../../utils/ChatHubspot";

const LoginView = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <AppBar className={props.classes.appBar}>
        <Card className={props.classes.card}>
          <CardMedia
            component="img"
            alt="Description de l'image"
            height="40"
            width="40"
            image="/logo192.png"
            title="Titre de l'image"
          />
        </Card>
      </AppBar>
      <Box
        className={props.classes.backgroundImg}
        sx={{
          display: "flex",
          // alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <div>
          <Box
            className={props.classes.container}
            sx={{
              bgcolor: "primary.main",
            }}
          >
            <form
              onSubmit={props.login}
              className={props.classes.formContainer}
            >
              <h1>{t("login.title")}</h1>
              <div>
                <div>
                  <TextField
                    name="user_email"
                    variant="outlined"
                    size="small"
                    onChange={props.handleFormChange}
                    label={t("login.email")}
                    className={props.classes.textfield}
                    autoComplete="username"
                  />
                  <br />
                  <TextField
                    name="user_password"
                    variant="outlined"
                    size="small"
                    onChange={props.handleFormChange}
                    label={t("login.password")}
                    className={props.classes.textfield}
                    autoComplete="current-password"
                    type="password"
                  />
                  <Button
                    variant="contained"
                    type="submit"
                    className={props.classes.validateForm}
                  >
                    {t("login.submit")}
                  </Button>
                  {props.state.showError ? (
                    <Alert severity="error">{props.state.errorMessage}</Alert>
                  ) : (
                    <div />
                  )}
                  {props.state.showSuccess ? (
                    <Alert severity="success">
                      {props.state.successMessage}
                    </Alert>
                  ) : (
                    <div />
                  )}
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography className={props.classes.orLogin}>
                      {t("login.or")}
                    </Typography>
                    <Divider
                      style={{ backgroundColor: "#768FDD" }}
                      className={props.classes.divider}
                    ></Divider>{" "}
                  </Box>
                  {/* <Button
                    variant="outlined"
                    href={apiHost + "/user/signinFacebook"}
                    className={props.classes.facebookButton}
                  >
                    Se connecter avec Facebook
                  </Button> */}
                  <Button
                    variant="outlined"
                    // href={apiHost + "/user/signinGoogle"}
                    className={props.classes.googleButton}
                    onClick={(e) => {
                      e.preventDefault();
                      const googleLoginUrl = props.getGoogleLoginUrl();
                      window.location.href = googleLoginUrl;
                    }}
                    startIcon={
                      <img
                        src="/images/google-icon.png"
                        alt="Google Logo"
                        style={{ width: 24, height: 24 }}
                      />
                    }
                  >
                    {t("login.signin_google")}
                  </Button>

                  <br />
                  <Link className={props.classes.signup} to="/signup">
                    {t("login.no_account")}
                  </Link>
                  <br />
                  <Link
                    className={props.classes.passwordForgotten}
                    to="/request-password-reset"
                  >
                    {t("login.forgot_password")}
                  </Link>
                </div>
              </div>
            </form>
          </Box>
          <Typography
            style={{
              textAlign: "center",
              marginTop: 12,
              fontSize: 16,
              fontWeight: 600,
              color: "#151B2F",
            }}
            sx={{
              color: "#151B2F",
              // color: "primary.main",
            }}
          >
            {t("login.more_info")}{" "}
            <a
              style={{ color: "#768FDD" }}
              href="https://landingzone.fr"
              target="_blank"
            >
              Landing Zone
            </a>
          </Typography>
        </div>
      </Box>
    </>
  );
};

export default LoginView;
