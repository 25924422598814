import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

const LanguageQueryHandler = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    // Obtenez la langue du navigateur (par exemple "fr", "en")
    const browserLanguage = navigator.language.startsWith("fr") ? "fr" : "en";

    // Analysez les paramètres de l'URL
    const searchParams = new URLSearchParams(location.search);

    // Ajoutez le paramètre "l" si non présent
    if (!searchParams.has("l")) {
      searchParams.set("l", browserLanguage);
      history.replace(`${location.pathname}?${searchParams.toString()}`);
    }
  }, [location, history]);

  return null; // Ce composant ne rend rien
};

export default LanguageQueryHandler;
