import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { frFR } from "@material-ui/core/locale";

import { BrowserRouter as Router, Route } from "react-router-dom";

import Home from "./pages/user/home/homeComponent";
import NewTour from "./pages/user/newTour/newTourComponent";
import NewZone from "./pages/user/newZone/newZoneComponent";
import NewEnigma from "./pages/user/newEnigma/newEnigmaComponent";
import Login from "./pages/user/login/loginComponent";
import Signup from "./pages/user/signup/signupComponent";
import ManageAccounts from "./pages/admin/manageAccounts";
import Account from "./pages/user/account/accountComponent";
import SignupUser from "./pages/admin/signupUser";
import ManageAccount from "./pages/admin/manageAccount";
import ResetPassword from "./pages/user/resetPassword/resetPasswordComponent";
import RequestResetPassword from "./pages/user/requestResetPassword/requestResetPasswordComponent";
import TourSelected from "./pages/user/tourSelected/tourSelectedComponent";
import analyticsComponent from "./pages/user/analytics/analyticsComponent";
import QrCodeStoresComponents from "./pages/user/qrCodeStores/qrCodeStore";
import checkYourMailsComponent from "./pages/user/checkYourMails/checkYourMailsComponent";
import Subscription from "./pages/user/subscription/subscriptionComponent";
import LanguageQueryHandler from "./utils/languageQueryHandler";

const theme = createTheme({
  palette: {
    primary: {
      main: "#232C48",
    },
    secondary: {
      main: "#e8c635",
    },
    danger: {
      main: "#DF0F0F",
    },
    darkGrey: {
      main: "#151B2F",
    },
    grey: {
      main: "#DED8BD",
    },
    lightGrey: {
      main: "#CACED5",
    },
    orange: {
      main: "#F48A21",
      dark: "#ab723a",
    },
    green: {
      main: "#22AA00",
    },
    background: {
      default: "#F6F8FA",
    },
  },
  overrides: {
    MuiInputLabel: {
      outlined: {
        "&$focused": {
          backgroundColor: "#F6F8FA",
          borderRadius: 4,
          padding: "2 6px",
          fontSize: 18,
        },
        "&$shrink": {
          backgroundColor: "#F6F8FA",
          borderRadius: 4,
          padding: "2 6px",
          fontSize: 18,
        },
      },
    },
    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: "#ccc",
      },
      colorSecondary: {
        "&$checked": {
          // Controls checked color for the thumb
          color: "#00E532",
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.9,
        backgroundColor: "#E5E5E5",
        "$checked$checked + &": {
          // Controls checked color for the track
          opacity: 0.7,
          backgroundColor: "#00E532",
        },
      },
    },
  },
});

const listStyle = {
  listStyle: "none",
};

const listItemStyle = {
  display: "inline",
  paddingRight: 20,
};

const pages = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/parcours",
    name: "NewTour",
    component: NewTour,
  },
  {
    path: "/parcours-selection",
    name: "TourSelected",
    component: TourSelected,
  },
  {
    path: "/statistiques",
    name: "Analytics",
    component: analyticsComponent,
  },
  {
    path: "/zone",
    name: "NewZone",
    component: NewZone,
  },
  {
    path: "/enigme",
    name: "newEnigma",
    component: NewEnigma,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/signup",
    name: "signup",
    component: Signup,
  },
  {
    path: "/admin/manage-accounts",
    name: "manageAccounts",
    component: ManageAccounts,
  },
  {
    path: "/admin/manage-account",
    name: "manageAccounts",
    component: ManageAccount,
  },
  {
    path: "/admin/signup-user",
    name: "signupUser",
    component: SignupUser,
  },
  {
    path: "/user/account",
    name: "account",
    component: Account,
  },
  {
    path: "/request-password-reset",
    name: "account",
    component: RequestResetPassword,
  },
  {
    path: "/reset-password",
    name: "account",
    component: ResetPassword,
  },
  {
    path: "/check-your-mails",
    name: "checkYourMails",
    component: checkYourMailsComponent,
  },
  {
    path: "/stores",
    name: "account",
    component: QrCodeStoresComponents,
  },
  {
    path: "/subscribe",
    name: "subscribe",
    component: Subscription,
  },
];

const AppRouter = () => {
  return (
    <Router>
      <ThemeProvider theme={theme} locale={frFR}>
        <LanguageQueryHandler />
        <CssBaseline />
        <div>
          <div className="page-wrapper">
            {pages.map((page) => (
              <Route
                key={page.path}
                path={page.path}
                exact
                component={page.component}
              />
            ))}
          </div>
        </div>
      </ThemeProvider>
    </Router>
  );
};

export default AppRouter;
