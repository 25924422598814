import React from "react";
import { Box, Grid } from "@material-ui/core";
import { withTranslation } from "react-i18next";

const NoSubscriptionBanner = ({
  t,
  role,
  hasTrial,
  redirectToSelectedPack,
  classes,
}) => {
  return (
    <Box>
      {role === "NO_SUBSCRIBTION" ||
        (role === "NEW_MEMBER" && (
          <Box className={classes.banner}>
            {redirectToSelectedPack ? (
              <>
                {hasTrial
                  ? t("no_subscribtion.text_trial")
                  : t("no_subscribtion.text")}{" "}
                <a
                  style={{ color: "white" }}
                  href={`/subscribe?pack=${redirectToSelectedPack}`}
                >
                  {t("no_subscribtion.here")}
                </a>
                .
              </>
            ) : (
              <>
                {hasTrial
                  ? t("no_subscribtion.text_trial")
                  : t("no_subscribtion.text")}{" "}
                <a style={{ color: "white" }} href="/subscribe">
                  {t("no_subscribtion.here")}
                </a>
                .
              </>
            )}
          </Box>
        ))}
    </Box>
  );
};

export default withTranslation()(NoSubscriptionBanner);
