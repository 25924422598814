import React, { Component } from "react";
import Alert from "@material-ui/lab/Alert";
import { AppBar, Box, Card, CardMedia, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const CheckYourMailsView = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <AppBar className={props.classes.appBar}>
        <Card className={props.classes.card}>
          <CardMedia
            component="img"
            alt="Description de l'image"
            height="40"
            width="40"
            image="/logo192.png"
            title="Titre de l'image"
          />
        </Card>
      </AppBar>
      <Box className={props.classes.backgroundImg}>
        <Box
          className={props.classes.container}
          sx={{
            bgcolor: "primary.main",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "white",
            marginLeft: 42,
            marginRight: 42,
          }}
        >
          <img
            className={props.classes.cardMedia}
            alt="Description de l'image"
            height="50"
            width="50"
            src="/images/mail.png"
            title="Titre de l'image"
          />
          <Typography className={props.classes.title}>
            {t("check_your_mails.account_activation")}
          </Typography>
          <Typography className={props.classes.subtitle}>
            {t("check_your_mails.email_instruction")}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default CheckYourMailsView;
