import React, { Component } from "react";
import { Redirect, useHistory, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Checkbox, Button, Box, Grid, Typography } from "@material-ui/core";
import styles from "./subscriptionStyles";
import SubscriptionView from "./subscriptionView";
import axios from "axios";
import ApiPayments from "../../../API/ApiPayments";
import ApiUser from "../../../API/ApiUser";
import { withTranslation } from "react-i18next";

class Subscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      isMonthly: true,
      packInfos: null,
      showLoadingView: true,
      role: null,
      priceId: null,
      hasTrial: true,
      dialogWaringOpened: false,
      updateAboStripeKeySelected: null,
    };

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleSubscriptionSelect = this.handleSubscriptionSelect.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.openDialog = this.openDialog.bind(this);
  }

  componentDidMount() {
    document.title = "Abonnement | Landing Zone";
    this.getPrices();
    this.getUser();
  }

  closeDialog() {
    this.setState((prevState) => ({
      ...prevState,
      dialogWaringOpened: false,
    }));
  }

  openDialog(stripeKey) {
    this.setState((prevState) => ({
      ...prevState,
      dialogWaringOpened: true,
      updateAboStripeKeySelected: stripeKey,
    }));
  }

  async getUser() {
    ApiUser.getAccount().then((response) => {
      if (response.status != 200 || !response.data) {
        this.setState((prevState) => ({
          ...prevState,
          showError: true,
          showSuccess: false,
          errorMessage: "Une erreur est survenu",
        }));

        return;
      }

      console.log("response.data ", response.data);

      this.setState((prevState) => ({
        ...prevState,
        role: response.data.role,
        priceId: response.data.stripeCurrentPriceId,
        hasTrial: response.data.hasTrial,
      }));
    });
  }

  async getPrices() {
    let response = await ApiPayments.getPackInfos();
    if (response && response.status === 200) {
      this.setState((prevState) => ({
        ...prevState,
        packInfos: response.data,
        showLoadingView: false,
      }));
    }
    return null;
  }

  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      ...prevState,
      isMonthly: !prevState.isMonthly,
    }));
  };

  handleSubscriptionSelect = async (stripePricesKey) => {
    console.log("selected key ", stripePricesKey);

    const { i18n } = this.props;
    const userLanguage = i18n.language;

    let key = stripePricesKey;
    if (!key) {
      key = this.state.updateAboStripeKeySelected;
    }
    console.log("selected key : ", key);
    let res = await ApiPayments.createCheckoutSession(key, userLanguage);
    if (res && res.status === 200) {
      if (res.data.message === "subscription-updated") {
        console.log("show loading view");
        this.setState((prevState) => ({
          ...prevState,
          showLoadingView: true,
        }));
        setTimeout(() => {
          window.location.href = res.data.redirectTo;
        }, 3000);
      } else {
        window.location.href = res.data.redirectTo;
      }
    }
  };

  render() {
    const { classes } = this.props;
    const { isMonthly } = this.state;

    const subscriptions = isMonthly
      ? {
          decouverteMonth: this.state.packInfos?.decouverteMonth,
          aventureMonth: this.state.packInfos?.aventureMonth,
          expertMonth: this.state.packInfos?.expertMonth,
        }
      : {
          decouverteEngagement: this.state.packInfos?.decouverteEngagement,
          aventureEngagement: this.state.packInfos?.aventureEngagement,
          expertEngagement: this.state.packInfos?.expertEngagement,
        };

    const { i18n } = this.props;
    const userLanguage = i18n.language;

    return (
      <SubscriptionView
        {...this.props}
        dialogWaringOpened={this.state.dialogWaringOpened}
        showLoadingView={this.state.showLoadingView}
        role={this.state.role}
        priceId={this.state.priceId}
        hasTrial={this.state.hasTrial}
        isMonthly={isMonthly}
        subscriptions={subscriptions}
        subscriptionsMonth={{
          decouverte: this.state.packInfos?.decouverteMonth,
          aventure: this.state.packInfos?.aventureMonth,
          expert: this.state.packInfos?.expertMonth,
        }}
        handleCheckboxChange={this.handleCheckboxChange}
        handleSubscriptionSelect={this.handleSubscriptionSelect}
        closeDialog={this.closeDialog}
        openDialog={this.openDialog}
        userLanguage={userLanguage}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(withTranslation()(Subscription))
);
