import { useEffect } from "react";

const HubspotChat = ({ user, isFr }) => {
  useEffect(() => {
    // Charger le script HubSpot
    const script = document.createElement("script");
    script.src = "//js-eu1.hs-scripts.com/144575761.js"; // Remplace par ton ID
    script.async = true;
    document.body.appendChild(script);

    if (!user) {
      return () => {
        document.body.removeChild(script);
      };
    }

    console.log("Tentative d'identification de l'utilisateur :", user);

    // // Fonction pour identifier l'utilisateur et forcer le chatflow
    // const initializeChat = async () => {

    //   if (window.HubSpotConversations) {

    //     try {
    //       // On doit définir loadImmediately avant la génération du token
    //       window.hsConversationsSettings = {
    //         loadImmediately: false,
    //       };
    //       // Récupérer le jeton via l'API
    //       //   const response = await ApiUser.getHubspotUserToken({
    //       //     email: user.email,
    //       //     firstName: user.firstname,
    //       //     lastName: user.lastname,
    //       //   });

    //       //   const { token } = response;
    //       //   console.log("Jeton HubSpot récupéré :", token);

    //       // Configuration de hsConversationsSettings
    //       //   window.hsConversationsSettings = {
    //       //     identificationEmail: user.email,
    //       //     identificationToken: token,
    //       //   };
    //     } catch (error) {
    //       console.error(
    //         "Erreur lors de la récupération du jeton HubSpot :",
    //         error
    //       );
    //     }
    //   } else {
    //     // Réessayer jusqu'à ce que l'API soit prête
    //     console.log("HubSpot API non prête, nouvelle tentative dans 500ms...");
    //     // setTimeout(initializeChat, 500);
    //   }
    // };

    // Lancer l'initialisation une fois que le script est chargé
    script.onload = () => {
      console.log("Script HubSpot chargé.");
      //   initializeChat();
    };

    // Nettoyage du script lors du démontage du composant
    return () => {
      document.body.removeChild(script);
    };
  }, [user]);

  return null;
};

export default HubspotChat;
