import React, { useState } from "react";
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import { Info } from "@material-ui/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  adaptiveSize: {
    maxWidth: 500,
    [theme.breakpoints.down("xs")]: {
      maxWidth: 300,
    },
  },
}));

const InfoPopover = ({ title, text, src, isWhite }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton
        aria-describedby={id}
        style={{ color: isWhite ? "white" : "#232C48" }}
        onClick={handleClick}
      >
        <Info />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div style={{ padding: "20px" }} className={classes.adaptiveSize}>
          <h3>{title}</h3>
          <p>
            {text.split("\\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </p>{" "}
          {src ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <img
                height="350"
                src={src}
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  borderRadius: "12px",
                }}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </Popover>
    </div>
  );
};

export default InfoPopover;
