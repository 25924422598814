import React from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Slider,
  Divider,
  IconButton,
  colors,
  Box,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import HomeIcon from "@material-ui/icons/Home";
import { Link } from "react-router-dom";
import qs from "qs";
import Alert from "@material-ui/lab/Alert";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CategoryIcon from "@material-ui/icons/Category";
import validator from "validator";

import ApiZone from "../../API/ApiZone";
import ApiEnigma from "../../API/ApiEnigma";
import ApiAdmin from "../../API/ApiAdmin";
import ApiUser from "../../API/ApiUser";
import DraggableList from "../components/draggableList";
import ListItemLink from "../components/listItemLink";
import DialogWarning from "../components/dialogWarning";
import MainAppBar from "../components/MainAppBar";
import { validateAccount } from "../../utils/validateAccount";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.darkGrey.main,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  optionUnlocked: {
    marginLeft: "12px",
    marginBottom: "6px",
    color: "green",
    fontWeight: 300,
    fontSize: "12px",
  },
  optionLocked: {
    marginLeft: "12px",
    marginBottom: "6px",
    color: "red",
    fontWeight: 300,
    fontSize: "12px",
  },
  formContainer: {
    paddingTop: "30px",
    paddingBottom: "30px",
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "20ch",
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
  },
  textField: {
    minWidth: 200,
  },
  list: {
    backgroundColor: theme.palette.lightGrey.main,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    borderRadius: 5,
    listStyle: "none",
  },
  buttonListPlus: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 5,
    color: "white",
  },
  returnButtonLink: {
    textDecoration: "none",
    margin: theme.spacing(2),
  },
  danger: {
    color: "red",
  },
});

class SignupUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdmin: false,
      isAdminSet: false,
      dialogWaringOpened: false,
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      account: {
        firstName: null,
        lastName: null,
        email: null,
        company: null,
        streetAddress: null,
        city: null,
        zipCode: null,
        siret: null,
        phoneNumber: null,
        role: "PACK_DECOUVERTE",
        maxNbOfTours: null,
        maxSessionsPerMonth: null,
        limitSessionPerMonth: null,
        maxZones: null,
        maxEnigmas: null,
      },
    };

    this.submitForm = this.submitForm.bind(this);
    this.updateForm = this.updateForm.bind(this);
  }

  componentDidMount() {
    document.title = "Nouveau compte | Landing Zone";

    this.setStateIsAdmin();
    this.updateForm({ target: { name: "role", value: "PACK_DECOUVERTE" } });
  }

  getLimitsForRole(role) {
    switch (role) {
      case "PACK_DECOUVERTE":
        return {
          maxNbOfTours: 1,
          maxZones: 15,
          maxEnigmas: 5,
          maxSessionsPerMonth: 25,
          limitSessionPerMonth: 50,
        };
      case "PACK_AVENTURE":
        return {
          maxNbOfTours: 3,
          maxZones: 30,
          maxEnigmas: 15,
          maxSessionsPerMonth: 75,
          limitSessionPerMonth: 150,
        };
      case "PACK_EXPERT":
        return {
          maxNbOfTours: 10,
          maxZones: 30,
          maxEnigmas: 15,
          maxSessionsPerMonth: 200,
          limitSessionPerMonth: 400,
        };
      case "NO_SUBSCRIBTION":
        return {
          maxNbOfTours: 0,
          maxZones: 0,
          maxEnigmas: 0,
          maxSessionsPerMonth: 0,
          limitSessionPerMonth: 0,
        };
      case "NEW_MEMBER":
        return {
          maxNbOfTours: 1,
          maxZones: 1,
          maxEnigmas: 3,
          maxSessionsPerMonth: 3,
          limitSessionPerMonth: 3,
        };
      case "ADMIN":
        return {
          maxNbOfTours: 99,
          maxZones: 99,
          maxEnigmas: 99,
          maxSessionsPerMonth: 500,
          limitSessionPerMonth: 500,
        };
      default:
        return {
          maxNbOfTours: 0,
          maxZones: 0,
          maxEnigmas: 0,
          maxSessionsPerMonth: 10,
          limitSessionPerMonth: 10,
        };
    }
  }

  updateForm(event) {
    let target = event.target;

    this.setState((prevState) => ({
      ...prevState,
      account: {
        ...prevState.account,
        [target.name]: target.value,
      },
    }));
    console.log("target.name ", target.name);
    if (target.name == "role") {
      let limits = this.getLimitsForRole(target.value);
      this.setState((prevState) => ({
        ...prevState,
        account: {
          ...prevState.account,
          maxNbOfTours: limits.maxNbOfTours,
          maxZones: limits.maxZones,
          maxEnigmas: limits.maxEnigmas,
          maxSessionsPerMonth: limits.maxSessionsPerMonth,
          limitSessionPerMonth: limits.limitSessionPerMonth,
        },
      }));
    }
  }

  async setStateIsAdmin() {
    let response = await ApiUser.isAdmin();

    if (response.status == 200) {
      this.setState((prevState) => ({
        ...prevState,
        isAdmin: response.data.isAdmin,
      }));
    }
  }

  async submitForm(event) {
    event.preventDefault();

    this.signup();
  }

  async signup() {
    if (
      !this.state.account.firstName ||
      !this.state.account.lastName ||
      !this.state.account.email ||
      !this.state.account.company ||
      !this.state.account.role ||
      !this.state.account.maxNbOfTours ||
      !this.state.account.maxSessionsPerMonth ||
      !this.state.account.limitSessionPerMonth
    ) {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: "Veuillez remplire tout les champs",
        showSuccess: false,
        dialogWaringOpened: false,
      }));

      return;
    }

    try {
      validateAccount(
        this.state.account.email,
        this.state.account.siret,
        this.state.account.phoneNumber,
        this.state.account.maxNbOfTours,
        this.state.account.maxSessionsPerMonth,
        this.state.account.limitSessionPerMonth
      );
    } catch (err) {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: err.message,
        showSuccess: false,
        dialogWaringOpened: false,
      }));

      return;
    }

    let maxToursInt = Number(this.state.account.maxNbOfTours);
    let maxSessionsPerMonthInt = Number(this.state.account.maxSessionsPerMonth);
    let addr = null;
    if (
      this.state.account.streetAddress &&
      this.state.account.city &&
      this.state.account.zipCode
    ) {
      addr =
        this.state.account.streetAddress +
        ";" +
        this.state.account.city +
        ";" +
        this.state.account.zipCode;
    }
    let response = await ApiAdmin.signupClientAccount(
      this.state.account.firstName,
      this.state.account.lastName,
      this.state.account.email,
      this.state.account.company,
      addr,
      this.state.account.siret,
      this.state.account.phoneNumber,
      this.state.account.role,
      maxToursInt,
      maxSessionsPerMonthInt,
      this.state.account.limitSessionPerMonth
    );
    console.log("status : ", response.status);
    console.log("data : ", response.data);
    if (response.status == 201) {
      console.log("B");
      this.setState((prevState) => ({
        ...prevState,
        showError: false,
        showSuccess: true,
        successMessage:
          "Le compte à bien été créer. Un email contenant le mot de passe à été envoyé à " +
          this.state.account.email,
        dialogWaringOpened: false,
      }));
    }
    // else if (response.status == 409) {
    //   this.setState(prevState => ({
    //     ...prevState,
    //     showError: true,
    //     errorMessage: "Cette email existe déjà",
    //     showSuccess: false,
    //     dialogWaringOpened: false,
    //   }));
    // }
    else {
      console.log("response.data ", response.data);
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: "Cette email existe déjà",
        showSuccess: false,
        dialogWaringOpened: false,
      }));
    }
  }

  openDialog() {
    this.setState((prevState) => ({
      ...prevState,
      dialogWaringOpened: true,
    }));
  }

  closeDialog() {
    this.setState((prevState) => ({
      ...prevState,
      dialogWaringOpened: false,
    }));
  }

  render() {
    const { classes } = this.props;

    if (!this.state.isAdmin && !this.state.isAdminSet) {
      return <div />;
    } else if (!this.state.isAdmin && this.state.isAdminSet) {
      return (
        <Redirect
          to={{
            pathname: "/login",
          }}
        />
      );
    }

    return (
      <>
        <MainAppBar
          headerText="Zone"
          showHomeButton={true}
          showAdminButton={this.state.isAdmin}
        />
        <Container className={classes.formContainer} maxWidth="md">
          <form
            className={classes.form}
            autoComplete="off"
            onSubmit={this.submitForm}
          >
            <TextField
              required
              name="firstName"
              id="filled-required"
              label="Prénom"
              variant="outlined"
              size="small"
              onChange={this.updateForm}
              className={classes.textField}
              inputProps={{ maxLength: 25 }}
            />
            <TextField
              required
              name="lastName"
              id="filled-required"
              label="Nom"
              variant="outlined"
              size="small"
              onChange={this.updateForm}
              className={classes.textField}
              inputProps={{ maxLength: 25 }}
            />
            <br />
            <TextField
              required
              name="email"
              id="filled-required"
              label="Email"
              type="email"
              variant="outlined"
              size="small"
              onChange={this.updateForm}
              className={classes.textField}
              inputProps={{ maxLength: 40 }}
            />
            <TextField
              required
              name="company"
              id="filled-required"
              label="Entreprise"
              variant="outlined"
              size="small"
              onChange={this.updateForm}
              className={classes.textField}
              inputProps={{ maxLength: 35 }}
            />
            <br />
            <br />
            <Divider />
            <br />
            <TextField
              name="phoneNumber"
              label="Téléphone"
              variant="outlined"
              size="small"
              type="tel"
              onChange={this.updateForm}
              className={classes.textField}
              inputProps={{ maxLength: 25 }}
            />
            <TextField
              name="siret"
              label="Siret"
              variant="outlined"
              size="small"
              type="number"
              onChange={this.updateForm}
              className={classes.textField}
              inputProps={{ maxLength: 25 }}
            />
            <br />
            <TextField
              name="streetAddress"
              label="Rue"
              variant="outlined"
              size="small"
              onChange={this.updateForm}
              className={classes.textField}
              inputProps={{ maxLength: 25 }}
            />
            <TextField
              name="city"
              label="Ville"
              variant="outlined"
              size="small"
              onChange={this.updateForm}
              className={classes.textField}
              inputProps={{ maxLength: 25 }}
            />
            <TextField
              name="zipCode"
              label="Code postal"
              variant="outlined"
              size="small"
              type="number"
              onChange={this.updateForm}
              className={classes.textField}
              inputProps={{ maxLength: 25 }}
            />
            <br />
            <br />
            <Divider />
            <br />

            {this.state.account.role === "PACK_DECOUVERTE" ? (
              <Typography className={classes.optionLocked}>
                Leaderboard désactivé
              </Typography>
            ) : (
              <Typography className={classes.optionUnlocked}>
                Leaderboard activé
              </Typography>
            )}

            <TextField
              required
              name="maxNbOfTours"
              id="outlined-number"
              label="Parcours max"
              variant="outlined"
              size="small"
              value={this.state.account.maxNbOfTours}
              onChange={this.updateForm}
              className={classes.textField}
              type="number"
              InputProps={{
                inputProps: {
                  max: 100,
                  min: 0,
                },
              }}
            />
            <TextField
              required
              name="maxZones"
              id="outlined-number"
              label="Zones max"
              variant="outlined"
              size="small"
              value={this.state.account.maxZones}
              onChange={this.updateForm}
              className={classes.textField}
              type="number"
              InputProps={{
                inputProps: {
                  max: 100,
                  min: 0,
                },
              }}
            />
            <TextField
              required
              name="maxEnigmas"
              id="outlined-number"
              label="Énigmes max"
              variant="outlined"
              size="small"
              value={this.state.account.maxEnigmas}
              onChange={this.updateForm}
              className={classes.textField}
              type="number"
              InputProps={{
                inputProps: {
                  max: 100,
                  min: 0,
                },
              }}
            />
            <br />
            <TextField
              required
              name="maxSessionsPerMonth"
              id="outlined-number"
              label="Sessions max par mois"
              variant="outlined"
              size="medium"
              value={this.state.account.maxSessionsPerMonth}
              onChange={this.updateForm}
              className={classes.textField}
              type="number"
              InputProps={{
                inputProps: {
                  max: 1000,
                  min: 0,
                },
              }}
            />
            <TextField
              required
              name="limitSessionPerMonth"
              id="outlined-number"
              label="Sessions payantes max par mois"
              variant="outlined"
              size="medium"
              value={this.state.account.limitSessionPerMonth}
              onChange={this.updateForm}
              className={classes.textField}
              type="number"
              InputProps={{
                inputProps: {
                  max: 3000,
                  min: 0,
                },
              }}
            />

            <InputLabel
              id="select-role"
              style={{ marginTop: 6, marginLeft: 6 }}
            >
              Type de compte *
            </InputLabel>
            <Select
              style={{ marginLeft: 6 }}
              name="role"
              labelId="select-response-type"
              id="select-response-type"
              value={this.state.account.role}
              onChange={this.updateForm}
            >
              <MenuItem value={"NO_SUBSCRIBTION"}>Pas d'abonnement</MenuItem>
              <MenuItem value={"NEW_MEMBER"}>Nouveau compte</MenuItem>
              <MenuItem value={"PACK_DECOUVERTE"}>Pack découverte</MenuItem>
              <MenuItem value={"PACK_AVENTURE"}>Pack aventure</MenuItem>
              <MenuItem value={"PACK_EXPERT"}>Pack expert</MenuItem>
              <MenuItem value={"ADMIN"} className={classes.danger}>
                Administrateur
              </MenuItem>
            </Select>

            <DialogWarning
              open={this.state.dialogWaringOpened}
              primaryText="Voulez vous vraiment donner à cette utilisateur le role d'administrateur"
              secondaryText="Cette utilisateur aura la possibilité d'éditer et de supprimer tout les comptes ainsi que tout les parcours."
              primaryAction={this.signup}
              closeAction={this.closeDialog}
              primaryButtonText="Valider"
              secondaryButtonText="Annuler"
            />

            <br />
            <br />
            <Divider />
            <br />

            {this.state.showError ? (
              <Alert severity="error">{this.state.errorMessage}</Alert>
            ) : (
              <div />
            )}
            {this.state.showSuccess ? (
              <Alert severity="success">{this.state.successMessage}</Alert>
            ) : (
              <div />
            )}
            <br />

            <Button variant="outlined" color="primary" type="submit">
              ENREGISTRER
            </Button>
          </form>
        </Container>
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SignupUser);
