import React from "react";
import TextField from "@material-ui/core/TextField";
import {
  Typography,
  Button,
  Slider,
  Divider,
  Box,
  Switch,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  OutlinedInput,
  DialogActions,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import QRCode from "qrcode.react";
import { BlockPicker, CirclePicker } from "react-color";
import { useTranslation } from "react-i18next";

import DraggableList from "../../components/draggableList";
import ListItemLink from "../../components/listItemLink";
import DialogWarning from "../../components/dialogWarning";
import MainAppBar from "../../components/MainAppBar";
import LoadingView from "../../components/loadingView";
import { ZoneMode } from "../../../constants/TourConstants";
import BreadcrumbTour from "../../components/breadcrumTour";
import SelectLocationDialog from "../../components/selectOnMapDialog";
import { ExpandMore, Map } from "@material-ui/icons";
import UnsavedChangesIndicator from "../../components/unsevedChangesIndicator";
import { Prompt } from "react-router-dom/cjs/react-router-dom.min";
import InfoPopover from "../../../utils/informationButton";
import NoSubscriptionBanner from "../home/components/noSubscriptionBanner";
import DisableToursPopover from "../../../utils/disableToursPopover";
import TutorialBubble from "../../../utils/tutorialBubble";

const NewZoneView = (props) => {
  const { t } = useTranslation();
  function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
      <Tooltip open={open} enterTouchDelay={0} placement="bottom" title={value}>
        {children}
      </Tooltip>
    );
  }

  return (
    <>
      {" "}
      <MainAppBar
        headerText="Zone"
        showHomeButton={true}
        showAdminButton={props.state.isAdmin}
      />
      <NoSubscriptionBanner
        role={props.state.role}
        redirectToSelectedPack={null}
        classes={props.classes}
      ></NoSubscriptionBanner>
      <DisableToursPopover
        userOpenedPopover={props.state.isDisableToursOpened}
      />
      <Prompt
        when={props.state.unsavedChanges}
        message={t("newZone.prompt_unsaved_changes")}
      />
      <Container className={props.classes.formContainer} maxWidth={false}>
        <UnsavedChangesIndicator show={props.state.unsavedChanges} />
        <BreadcrumbTour
          tourId={props.state.tourId}
          zoneId={props.state.zoneId}
          zoneMode={props.state.formDatas.zoneMode}
        />
        <br></br>
        <form
          className={props.classes.form}
          autoComplete="off"
          onSubmit={props.submitForm}
        >
          <Grid
            container
            direction="row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              direction="column"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box className={props.classes.textFieldContainer}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: 10,
                  }}
                >
                  <Typography className={props.classes.switchFormControl}>
                    {t("newZone.activer_la_zone")}
                  </Typography>
                  <InfoPopover
                    title={t("newZone.activer_la_zone")}
                    text={t("newZone.activer_la_zone_description")}
                    isWhite={true}
                  ></InfoPopover>
                </div>

                <Switch
                  checked={props.state.formDatas.isActive}
                  onChange={props.updateIsActive}
                />
              </Box>

              <Box className={props.classes.textFieldContainer}>
                <Typography className={props.classes.switchFormControl}>
                  {t("newZone.durée_limite_zone")}
                </Typography>

                <TextField
                  required
                  id="filled-required"
                  label=""
                  variant="outlined"
                  size="small"
                  type="number"
                  defaultValue={props.state.formDatas.timer}
                  onChange={props.updateTimer}
                  className={props.classes.textField}
                  InputProps={{
                    inputProps: {
                      max: 120,
                      min: 0,
                    },
                  }}
                  key={
                    props.state.zoneLoaded ? "timerNotLoaded" : "timerLoaded"
                  }
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              direction="column"
              style={{
                display: "flex",
              }}
              className={props.classes.alignCenterSm}
            >
              <Box
                className={props.classes.textFieldContainerLight}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <TextField
                  required
                  id="filled-required"
                  label={t("newZone.nom_de_la_zone")}
                  variant="outlined"
                  size="small"
                  defaultValue={props.state.formDatas.name}
                  onChange={props.updateName}
                  className={props.classes.textField}
                  multiline={true}
                  inputProps={{ maxLength: 1000 }}
                  key={props.state.zoneLoaded ? "nameNotLoaded" : "nameLoaded"}
                />
                <InfoPopover
                  title={t("newZone.nom_de_la_zone")}
                  text={t("newZone.nom_de_la_zone_description")}
                ></InfoPopover>
              </Box>

              <Box className={props.classes.textFieldContainerLight}>
                <FormControl className={props.classes.select}>
                  <InputLabel variant="outlined" id="select-response-type">
                    {t("newZone.type_de_zone")}
                  </InputLabel>
                  {props.state.gameMode == ZoneMode.MAP ? (
                    <Select
                      labelId="select-response-type"
                      id="select-response-type"
                      value={props.state.formDatas.zoneMode}
                      onChange={props.handleZoneModeChange}
                    >
                      <MenuItem value={ZoneMode.MAP}>
                        {t("newZone.type_de_zone_options.map")}
                      </MenuItem>
                      <MenuItem value={ZoneMode.QRCODE}>
                        {t("newZone.type_de_zone_options.qrcode")}
                      </MenuItem>
                    </Select>
                  ) : props.state.gameMode == ZoneMode.QRCODE ? (
                    <Select
                      labelId="select-response-type"
                      id="select-response-type"
                      value={props.state.formDatas.zoneMode}
                      onChange={props.handleZoneModeChange}
                    >
                      <MenuItem value={ZoneMode.QRCODE}>
                        {t("newZone.type_de_zone_options.qrcode")}
                      </MenuItem>
                    </Select>
                  ) : (
                    <Select
                      labelId="select-response-type"
                      id="select-response-type"
                      value={props.state.formDatas.zoneMode}
                      onChange={props.handleZoneModeChange}
                    >
                      <MenuItem value={ZoneMode.AUTOMATIC_LAUNCH}>
                        {t("newZone.type_de_zone_options.automatic_launch")}
                      </MenuItem>
                    </Select>
                  )}
                </FormControl>
              </Box>

              {props.state.formDatas.zoneMode == ZoneMode.MAP ? (
                <>
                  <Typography
                    id="discrete-slider"
                    style={{ color: "black", fontWeight: 700, marginTop: 6 }}
                    gutterBottom
                  >
                    {t("newZone.périmètre_de_la_zone")}
                  </Typography>
                  <Slider
                    getAriaValueText={props.valueSliderTourTimer}
                    aria-labelledby="discrete-slider"
                    step={5}
                    marks
                    min={5}
                    max={300}
                    valueLabelDisplay="on"
                    valueLabelFormat={(value) => `${value} mètres`}
                    value={props.state.formDatas.zoneRadius}
                    onChange={props.updateZoneRadius}
                    ValueLabelComponent={ValueLabelComponent}
                    variant="outlined"
                    size="small"
                    key={
                      props.state.zoneLoaded
                        ? "zoneRadiusNotLoaded"
                        : "zoneRadiusLoaded"
                    }
                  />
                  <Grid
                    className={props.classes.alignCenterSm}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      display: "flex",
                      alignItems: "center", // Centre verticalement
                      justifyContent: "center", // Centre horizontalement
                      marginTop: 34,
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      className={props.classes.textFieldCentereSm}
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      style={{ marginBottom: 14 }}
                    >
                      <Button
                        onClick={() => props.openMapDialog()}
                        className={props.classes.mapButton}
                        startIcon={<Map />}
                      >
                        {t("newTour.select_pos")}
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Typography>{t("newTour.show_coords")}</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                          <TextField
                            className={props.classes.textFieldLgSm}
                            style={{ width: "90%" }}
                            id="filled-required"
                            label={t("newZone.longitude")}
                            variant="outlined"
                            size="small"
                            value={
                              props.state.formDatas.coords.longitude != 0
                                ? props.state.formDatas.coords.longitude
                                : ""
                            }
                            onChange={props.updateCoordsLon}
                            inputProps={{
                              className: props.classes.whiteTextfield,
                              pattern: "-?(([1-9][0-9]*)|0)?(\\.[0-9]*)?",
                            }}
                            key={
                              props.state.zoneLoaded
                                ? "coordsLonNotLoaded"
                                : "coordsLonLoaded"
                            }
                          />
                          <TextField
                            style={{ width: "90%" }}
                            className={props.classes.textFieldLgSm}
                            id="filled-required"
                            label={t("newZone.latitude")}
                            variant="outlined"
                            size="small"
                            value={
                              props.state.formDatas.coords.latitude != 0
                                ? props.state.formDatas.coords.latitude
                                : ""
                            }
                            onChange={props.updateCoordsLat}
                            inputProps={{
                              className: props.classes.whiteTextfield,
                              pattern: "-?(([1-9][0-9]*)|0)?(\\.[0-9]*)?",
                            }}
                            key={
                              props.state.zoneLoaded
                                ? "coordsLatNotLoaded"
                                : "coordsLatLoaded"
                            }
                          />
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                </>
              ) : props.state.formDatas.zoneMode == ZoneMode.QRCODE ? (
                <>
                  <br />
                  <br />
                  {props.state.zoneId !== -1 ? (
                    <>
                      <Grid container className={props.classes.qrContainer}>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            // marginLeft: 20,
                          }}
                        >
                          <QRCode
                            id="zone_qr_code"
                            includeMargin={true}
                            size={190}
                            value={props.state.formDatas.uuid}
                            bgColor={props.state.qrCodeBgColor}
                            fgColor={props.state.qrCodeFgColor}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          direction="column"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            onClick={props.downloadQR}
                            className={props.classes.qrButton}
                          >
                            {t("newZone.qrcode_text.telecharger_qr_code")}
                          </Button>
                          <Box>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "start",
                                width: "100%",
                                marginBottom: 10,
                              }}
                            >
                              <Button
                                className={props.classes.linkQrCodeButton}
                                onClick={props.openQrCodeDialog}
                              >
                                {t("newZone.qrcode_text.importer_qr_code")}
                              </Button>
                              <InfoPopover
                                title={t(
                                  "newZone.qrcode_text.importer_qr_code"
                                )}
                                text={t(
                                  "newZone.qrcode_text.importer_qr_code_description"
                                )}
                              ></InfoPopover>
                            </div>
                            <Dialog
                              disableEscapeKeyDown
                              open={props.state.qrCodeDialogOpen}
                              onClose={props.handleCloseCopyUuid}
                              fullWidth
                              maxWidth="sm"
                            >
                              <DialogTitle>
                                {t("newZone.qrcode_text.choisir_la_zone")}
                              </DialogTitle>
                              <DialogContent>
                                <Box
                                  component="form"
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    width: "100%",
                                    flexDirection: "column",
                                  }}
                                >
                                  <FormControl sx={{ m: 1, width: "100%" }}>
                                    <InputLabel htmlFor="demo-dialog-native">
                                      {t("newZone.qrcode_text.parcours")}
                                    </InputLabel>
                                    <Select
                                      value={props.state.selectedTourQrCode}
                                      onChange={props.handleChangeTourQrCode}
                                      style={{
                                        width: "100%",
                                      }}
                                      input={
                                        <OutlinedInput
                                          label={t(
                                            "newZone.qrcode_text.parcours"
                                          )}
                                          id="dialog-select-parcours"
                                          sx={{ width: "100%" }}
                                        />
                                      }
                                    >
                                      {props.state.toursUsers.map((tour) => {
                                        if (tour.id !== props.state.tourId) {
                                          return (
                                            <option
                                              key={tour.id}
                                              value={tour.id}
                                            >
                                              {tour.title}
                                            </option>
                                          );
                                        }
                                      })}
                                    </Select>
                                  </FormControl>
                                  <Box style={{ height: "10px" }}></Box>
                                  {props.state.selectedTourQrCode !== null ? (
                                    <>
                                      <FormControl
                                        sx={{
                                          m: 1,
                                          width: "100%",
                                        }}
                                      >
                                        <InputLabel id="demo-dialog-select-label">
                                          {t("newZone.qrcode_text.zone")}
                                        </InputLabel>
                                        <Select
                                          id="dialog-select-zone"
                                          value={
                                            props.state.selectedZoneQrCodeUuid
                                          }
                                          onChange={
                                            props.handleChangeZoneQrCode
                                          }
                                          style={{
                                            width: "100%",
                                          }}
                                          input={
                                            <OutlinedInput
                                              id="dialog-select-zone"
                                              label="Zone"
                                              sx={{ width: "100%" }}
                                            />
                                          }
                                        >
                                          {props.state.zonesSelectedQrTour.map(
                                            (zone) => {
                                              if (
                                                zone.zoneMode == ZoneMode.QRCODE
                                              ) {
                                                return (
                                                  <option
                                                    key={zone.id}
                                                    value={zone.uuid}
                                                  >
                                                    {zone.name}
                                                  </option>
                                                );
                                              }
                                            }
                                          )}
                                        </Select>
                                      </FormControl>
                                      {props.state.selectedZoneQrCodeUuid ? (
                                        <>
                                          <Button
                                            style={{ marginTop: "12px" }}
                                            onClick={
                                              props.handleResetZoneLinked
                                            }
                                          >
                                            {t(
                                              "newZone.qrcode_text.reinitialiser_qr_code"
                                            )}
                                          </Button>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Box>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  color="primary"
                                  onClick={props.handleCloseCopyUuid}
                                >
                                  {t("newZone.dialog_warning.annuler_button")}
                                </Button>
                                <Button
                                  color="#ffffff"
                                  backgroundColor="#841584"
                                  onClick={props.handleValidateCopyUuid}
                                >
                                  {t("newZone.dialog_warning.valider")}
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </Box>
                          <Box>
                            <Box sx={{ m: 1 }}>
                              <CirclePicker
                                colors={[
                                  "#FFFFFF",
                                  "#E3E8FA",
                                  "#E7FAEE",
                                  "#F9E4E4",
                                  "#E9FAFA",
                                ]}
                                styles={{ margin: "100px" }}
                                color={props.state.qrCodeBgColor}
                                onChangeComplete={
                                  props.handleQrCodeBackgroundColorChanged
                                }
                              ></CirclePicker>
                            </Box>
                            <Box sx={{ m: 1 }}>
                              <CirclePicker
                                colors={[
                                  "#000000",
                                  "#031244",
                                  "#03461B",
                                  "#470303",
                                  "#023D3D",
                                ]}
                                color={props.state.qrCodeFgColor}
                                onChangeComplete={
                                  props.handleQrCodeForegroundColorChanged
                                }
                              />
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <div></div>
                  )}
                </>
              ) : (
                <div></div>
              )}
            </Grid>
          </Grid>

          <br></br>
          <Divider></Divider>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginBottom: 10,
            }}
          >
            <FormControlLabel
              style={{ marginLeft: 6 }}
              control={
                <Checkbox
                  name="enigmasInRandomOrder"
                  checked={props.state.formDatas.enigmasInRandomOrder}
                  onChange={props.updateEnigmasInRandomOrder}
                  color="primary"
                />
              }
              label={t("newZone.les_enigmes_se_lancent_ordre_aléatoire")}
            />
          </div>

          <Box className={props.classes.listContainer}>
            <div className={props.classes.list}>
              {props.state.enigmasLoaded ? (
                <DraggableList
                  items={props.enigmasItems}
                  onDragEnd={props.onDragEnd}
                  onDelete={props.openDeleteEnigmaDialog}
                />
              ) : (
                <div></div>
              )}
              <Box className={props.classes.buttonListPlus}>
                <ListItemLink
                  to={`/enigme?tourId=${props.state.tourId}&zoneId=${props.state.zoneId}`}
                  key={1000}
                  primary={t("newZone.nouvelle_énigme")}
                  icon={<AddCircleOutlineIcon />}
                  deleteButon={false}
                  disabled={
                    props.state.zoneId != -1 &&
                    props.state.role !== "NO_SUBSCRIBTION" &&
                    props.state.tourDisabled !== true
                      ? false
                      : true
                  }
                />
              </Box>
            </div>
          </Box>

          {props.state.showError ? (
            <Alert severity="error">{props.state.errorMessage}</Alert>
          ) : (
            <div />
          )}
          {props.state.showSuccess ? (
            <Alert severity="success">{props.state.successMessage}</Alert>
          ) : (
            <div />
          )}
          <br />

          <DialogWarning
            open={props.state.dialogWaringOpened}
            primaryText={t("newZone.primaryTextDelete")}
            secondaryText={t("newZone.secondaryTextDelete")}
            primaryAction={props.deleteEnigma}
            closeAction={props.closeDeleteEnigmaDialog}
            idItem={props.state.enigmaIdToDelete}
            primaryButtonText={t("parcours_list.delete_button")}
            secondaryButtonText={t("parcours_list.cancel_button")}
          />
          <LoadingView
            className={props.classes.loadingView}
            open={props.state.loadingViewOpened}
          />

          <br />

          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Link
              className={props.classes.returnButtonLink}
              to={`/parcours?id=${props.state.tourId}`}
            >
              <Button variant="outlined" color="darkGrey">
                {t("newZone.retour_button")}
              </Button>
            </Link>
            <Button
              variant="contained"
              color="primary"
              className={`${props.classes.saveButton} ${
                props.state.role === "NO_SUBSCRIBTION" ||
                props.state.tourDisabled
                  ? props.classes.buttonDisabled
                  : ""
              }`}
              type="submit"
            >
              {props.state.editing ? (
                <div>{t("newZone.enregistrer_button")}</div>
              ) : (
                <div>{t("newZone.ajouter_button")}</div>
              )}
            </Button>
          </Box>
        </form>
        {props.state.tutorialSteps?.step3 == false && (
          <TutorialBubble
            style={{ position: "absolute" }}
            message={t("tuto.step3")}
          ></TutorialBubble>
        )}
        {props.state.tutorialSteps?.step5 == false &&
          props.state.tutorialSteps?.step1 == true &&
          props.state.tutorialSteps?.step2 == true &&
          props.state.tutorialSteps?.step3 == true &&
          props.state.tutorialSteps?.step4 == true && (
            <TutorialBubble
              style={{ position: "absolute" }}
              message={`${t("tuto.step5Part1")}${
                props.state.testCode ? props.state.testCode : ""
              }${t("tuto.step5Part2")}`}
            ></TutorialBubble>
          )}
        <SelectLocationDialog
          currentZoneId={props.state.zoneId}
          zones={props.state.zones}
          lat={props.showLatOnMap}
          lng={props.showLngOnMap}
          zoneRadius={props.state.formDatas.zoneRadius}
          initLocationLat={props.tourLocationLat}
          initLocationLng={props.tourLocationLng}
          coordsNE={props.state.cameraBoundsNE}
          coordsSW={props.state.cameraBoundsSW}
          open={props.mapDialogOpen}
          onClose={() => props.closeMapDialog()}
          onLocationSelected={(location) => {
            props.setLocation(location);
          }}
        />{" "}
      </Container>
    </>
  );
};

export default NewZoneView;
