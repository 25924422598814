const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.darkGrey.main,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  loadingView: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    flexGrow: 1,
    marginTop: 18,
    marginBottom: 18,
    fontWeight: 900,
  },
  formContainer: {
    paddingTop: "30px",
    paddingBottom: "30px",
    width: "90%",
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "20ch",
    },
  },
  imageCardInGameBackground: {
    marginLeft: 10,
    width: 411 / 6,
    height: 794 / 6,
    [theme.breakpoints.up("md")]: {
      marginLeft: 10,
      width: 411 / 3,
      height: 794 / 3,
    },
    marginTop: 20,
  },
  imageCardBackground: {
    marginLeft: 10,
    width: 411 / 4,
    height: 794 / 4,
    [theme.breakpoints.up("md")]: {
      width: 411 / 3,
      height: 794 / 3,
    },
  },
  hrVertical: {
    borderLeft: "1px solid #C1C6D3",
    height: "700px",
    flexShrink: 0, // Empêche l'élément de s'étendre
    marginRight: -10,
    width: 1,
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "inline",
    },
  },
  gridBorder: {
    borderRight: null,
    [theme.breakpoints.up("lg")]: {
      borderRight: "1px solid #C1C6D3",
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  imageCardBackgroundTab: {
    marginLeft: 10,
    width: 1024 / 8,
    height: 1310 / 8,
    [theme.breakpoints.up("md")]: {
      width: 1024 / 4,
      height: 1310 / 4,
    },
  },
  imageCardBackgroundInList: {
    marginLeft: 10,
    width: 50,
    height: 50,
    marginTop: 0,
    [theme.breakpoints.up("md")]: {
      width: 100,
      height: 100,
    },
  },
  imageCardBackgroundInListTab: {
    marginLeft: 10,
    width: 50,
    height: 50,
    marginTop: 0,
    [theme.breakpoints.up("md")]: {
      width: 100,
      height: 100,
    },
  },
  alignCenterXs: {
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "start",
      alignItems: "start",
    },
  },
  mapButton: {
    backgroundColor: theme.palette.orange.main,
    color: "#ffffff",
    fontSize: 10,
    borderRadius: 8,
    height: 40,
    marginTop: 9,
    "&:hover": {
      backgroundColor: theme.palette.orange.dark,
    },
  },
  imageCardAnnotation: {
    marginLeft: 10,
    width: 75,
    height: 75,
    marginTop: 20,
  },
  imageCardMedia: {
    width: "100%",
    height: "100%",
    backgroundColor: "#E5E8EB",
  },
  uploadFile: {
    color: "white",
    width: "50%",
    marginTop: 10,
    marginLeft: 10,
    marginBottom: 6,
    backgroundColor: "#F48A21",
    "&:hover": {
      backgroundColor: theme.palette.orange.dark,
    },
  },
  link: {
    textDecoration: "none",
  },
  listContainer: {
    display: "flex",
    justifyContent: "center",
  },
  list: {
    backgroundColor: theme.palette.lightGrey.main,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    borderRadius: 5,
    listStyle: "none",
    width: "60%",
  },
  buttonListPlus: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 5,
    color: "white",
  },
  imageDimensionsText: {
    paddingLeft: 8,
    color: "#494949ff",
    fontSize: 12,
  },
  imageInfos: {
    paddingTop: 18,
    paddingLeft: 8,
    color: "#494949ff",
    fontSize: 12,
    fontWeight: 600,
  },
  textFieldContainer: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
    padding: 18,
    width: "80%",
  },
  textFieldLight: {
    backgroundColor: "white",
    borderRadius: 8,
    marginBottom: 32,
    width: "90%",
  },
  helperText: {
    marginTop: 8,
    fontSize: 12,
    fontWeight: 100,
  },
  headerLocations: {
    fontSize: 12,
    fontWeight: 800,
    marginLeft: 12,
  },
  valueSliderLabel: {
    left: "auto !important",
    top: "-2em",
    "& *": {
      background: "transparent",
      color: "#000",
    },
  },
  pannel: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
    marginRight: 12,
  },
  saveButton: {
    backgroundColor: theme.palette.orange.main,
    color: "#ffffff",
    fontSize: 18,
    fontWeight: 900,
    borderWidth: "0px",
    "&:hover": {
      backgroundColor: theme.palette.orange.dark,
    },
  },
  textfieldWhite: {
    backgroundColor: "white",
  },
  banner: {
    color: "#ffffff",
    backgroundColor: theme.palette.orange.main,
    textAlign: "center",
    fontSize: 16,
    fontWeight: 900,
    padding: "15px",
    color: "white",

    // marginBottom: "10px",
  },
  buttonDisabled: {
    backgroundColor: "#8e999d",
    cursor: "default",
    "&:hover": {
      backgroundColor: "#8e999d",
    },
  },

  // thumb: {
  //   height: 15,
  //   width: 15,
  //   backgroundColor: "#fff",
  //   border: "2px solid currentColor",
  //   marginTop: -6,
  //   marginLeft: -11,
  //   "&:focus, &:hover, &$active": {
  //     boxShadow: "inherit",
  //   },
  // },
  // track: {
  //   height: 8,
  //   borderRadius: 4,
  //   backgroundColor: "#fff",
  // },
  // rail: {
  //   height: 8,
  //   borderRadius: 4,
  //   backgroundColor: "#fff",
  // },
  // mark: {
  //   backgroundColor: "#bfbfbf",
  //   height: 10,
  //   width: 10,
  //   borderRadius: "50%",
  //   marginTop: -3,
  // },
  // markActive: {
  //   backgroundColor: "currentColor",
  // },
});

export default styles;
