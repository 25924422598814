import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Card,
  CardMedia,
  Box,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/HomeRounded";
import AccountIcon from "@material-ui/icons/AccountCircle";
import HelpIcon from "@material-ui/icons/Help";
import ExitToApp from "@material-ui/icons/ExitToApp";
import SupervisorAccount from "@material-ui/icons/RecentActors";
import { withTranslation } from "react-i18next";

import AuthStorage from "../../utils/authStorage";
import ApiUser from "../../API/ApiUser";
import HubspotChat from "../../utils/ChatHubspot";

const styles = (theme) => ({
  appBar: {
    backgroundColor: theme.palette.darkGrey.main,
    paddingBottom: 10,
    paddingTop: 10,
  },
  title: {
    flexGrow: 1,
    marginLeft: 18,
    fontWeight: 600,
    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  cardMedia: {
    height: 40,
    width: 40,
  },
  link: {
    textDecoration: "none",
    paddingBottom: 10,

    display: "flex",
    alignItems: "center",

    "&:hover": {
      "& $iconText": {
        color: "lightgray",
      },
      "& $icon": {
        fill: "lightgray",
      },
    },
    "&:hover:last-child": {
      "& $icon": {
        fill: "red",
      },
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "white",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  mainLinkAction: {
    textDecoration: "none",
    flexGrow: 1,
    align: "center",
    color: theme.palette.orange.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  mainButtonAction: {
    color: "#ffffff",
    backgroundColor: theme.palette.orange.main,
    borderRadius: 24,
    fontWeight: 900,
    "&:hover": {
      backgroundColor: theme.palette.orange.dark,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  mainButtonActionDeactivated: {
    color: "#ffffff",
    backgroundColor: "#8e999d",
    borderRadius: 24,
    fontWeight: 900,
    cursor: "default",
    "&:hover": {
      backgroundColor: "#8e999d",
    },
  },
  homeIcon: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },

  desktopButtons: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  label: {
    color: "white",
    flexDirection: "column",
  },

  icon: {
    fontSize: "28px !important",
  },
  iconText: {
    textTransform: "none",
    fontSize: "15px",
    marginLeft: "15px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  mobileBtn: {
    textDecoration: "none",
    color: "black",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

class MainAppBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuAnchorEl: null,
    };

    this.logout = this.logout.bind(this);
    this.handleMenuOpen = this.handleMenuOpen.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
  }

  logout() {
    // AuthStorage.deleteTokens();
    localStorage.setItem("token", null);
    ApiUser.logout();
  }

  handleMenuOpen(event) {
    this.setState({ menuAnchorEl: event.currentTarget });
  }

  handleMenuClose() {
    this.setState({ menuAnchorEl: null });
  }

  render() {
    const { classes } = this.props;
    const { menuAnchorEl } = this.state;
    const isMenuOpen = Boolean(menuAnchorEl);
    const { t } = this.props;
    const userLanguage = this.props.i18n.language;
    const isFr = userLanguage.startsWith("fr");

    return (
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Link to="/" className={classes.homeIcon}>
            <IconButton aria-label="acceuil" edge="end" color="secondary">
              <Card>
                <CardMedia
                  className={classes.cardMedia}
                  component="img"
                  alt="Logo"
                  height="40"
                  width="40"
                  image="/logo192.png"
                  title="Logo"
                />
              </Card>
            </IconButton>
          </Link>

          <Typography variant="h6" className={classes.title}>
            {this.props.headerText}
          </Typography>

          {this.props.actionUrl &&
          this.props.actionText &&
          this.props.role !== "NO_SUBSCRIBTION" ? (
            <Link
              to={this.props.actionUrl}
              className={`${classes.link} ${classes.mainLinkAction}`}
            >
              <Button variant="contained" className={classes.mainButtonAction}>
                {this.props.actionText}
              </Button>
            </Link>
          ) : (
            this.props.actionUrl &&
            this.props.actionText &&
            this.props.role === "NO_SUBSCRIBTION" && (
              <Box style={{ width: "100%", textAlign: "center" }}>
                <Button
                  variant="contained"
                  className={classes.mainButtonActionDeactivated}
                >
                  {this.props.actionText}
                </Button>
              </Box>
            )
          )}

          {/* Desktop buttons */}
          <div className={classes.desktopButtons}>
            {this.props.showAdminButton && (
              <Link to="/admin/manage-accounts" className={classes.link}>
                <Button className={`${classes.iconText} ${classes.label}`}>
                  {t("navigation.clients")}
                </Button>
              </Link>
            )}
            <Link to="/" className={classes.link}>
              <Button className={`${classes.iconText} ${classes.label}`}>
                {t("navigation.tours")}
              </Button>
            </Link>
            <a
              href={
                isFr
                  ? "https://landingzone.app/aide/"
                  : "https://landingzone.app/online-help/"
              }
              target="_blank"
              className={classes.link}
            >
              <Button className={`${classes.iconText} ${classes.label}`}>
                {t("navigation.help")}
              </Button>
            </a>
            <Link to="/user/account" className={classes.link}>
              <Button className={`${classes.iconText} ${classes.label}`}>
                {t("navigation.account")}
              </Button>
            </Link>
            <Link to="/login" className={classes.link} onClick={this.logout}>
              <Button className={`${classes.iconText} ${classes.label}`}>
                {t("navigation.logout")}
              </Button>
            </Link>
          </div>

          {/* Mobile menu */}
          <IconButton
            edge="end"
            aria-label="menu"
            className={classes.menuButton}
            onClick={this.handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={menuAnchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMenuOpen}
            onClose={this.handleMenuClose}
          >
            {this.props.showAdminButton && (
              <MenuItem onClick={this.handleMenuClose}>
                <Link to="/admin/manage-accounts" className={classes.mobileBtn}>
                  Clients
                </Link>
              </MenuItem>
            )}
            <MenuItem onClick={this.handleMenuClose}>
              <Link to="/" className={classes.mobileBtn}>
                Parcours
              </Link>
            </MenuItem>
            <MenuItem onClick={this.handleMenuClose}>
              <a
                href="https://landingzone.fr/aide/"
                target="_blank"
                className={classes.mobileBtn}
              >
                Aide
              </a>
            </MenuItem>
            <MenuItem onClick={this.handleMenuClose}>
              <Link to="/user/account" className={classes.mobileBtn}>
                Compte
              </Link>
            </MenuItem>
            <MenuItem onClick={this.handleMenuClose}>
              <Link
                className={classes.mobileBtn}
                to="/login"
                onClick={this.logout}
              >
                Déconnexion
              </Link>
            </MenuItem>
          </Menu>
        </Toolbar>
        <HubspotChat user={this.props.user} isFr={this.props.isFr} />
      </AppBar>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation()(MainAppBar)
);
