import React from "react";
import Alert from "@material-ui/lab/Alert";

import DraggableList from "../../components/draggableList";
import DialogWarning from "../../components/dialogWarning";
import MainAppBar from "../../components/MainAppBar";
import { Box, Grid } from "@material-ui/core";
import ParcoursListComponent from "./components/parcoursList/parcoursListComponent";
import CodeListComponent from "./components/parcoursCodes/codeListComponent";
import NoSubscriptionBanner from "./components/noSubscriptionBanner";
import DisableToursPopover from "../../../utils/disableToursPopover";
import FloatingVideoLink from "../../components/floatingVideoLinks";
import { useTranslation } from "react-i18next";
import TutorialBubble from "../../../utils/tutorialBubble";
import HubspotChat from "../../../utils/ChatHubspot";

const HomeView = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <MainAppBar
        headerText={t("home.header_text")}
        actionText={t("home.action_text")}
        actionUrl="/parcours"
        showAdminButton={props.state.isAdmin}
        role={props.role}
        user={props.state.user}
      />
      <NoSubscriptionBanner
        role={props.role}
        hasTrial={props.hasTrial}
        redirectToSelectedPack={props.state.user?.redirectToSelectedPack}
        classes={props.classes}
      ></NoSubscriptionBanner>
      {/* <FloatingVideoLink role={props.role}></FloatingVideoLink> */}
      <DisableToursPopover
        userOpenedPopover={props.state.isDisableToursOpened}
      />
      <Grid container>
        <Grid item xs={4}>
          <Box
            className={`${props.classes.pannel}`}
            sx={{ p: 2, mt: 2, ml: 1, mr: 1 }}
          >
            <ParcoursListComponent
              {...props}
              role={props.role}
              tourIdSelected={props.state.tourIdSelected}
            ></ParcoursListComponent>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box
            className={`${props.classes.pannel}`}
            sx={{ p: 2, mt: 2, mr: 1 }}
          >
            <CodeListComponent
              {...props}
              role={props.role}
              tourId={props.state.tourIdSelected}
            ></CodeListComponent>
          </Box>
        </Grid>
      </Grid>
      {props.state.user?.tutorialSteps?.step1 == false && (
        <TutorialBubble
          style={{ position: "absolute" }}
          message={t("tuto.step1")}
        ></TutorialBubble>
      )}
      {props.state.user?.tutorialSteps?.step5 == false &&
        props.state.user?.tutorialSteps?.step1 == true &&
        props.state.user?.tutorialSteps?.step2 == true &&
        props.state.user?.tutorialSteps?.step3 == true &&
        props.state.user?.tutorialSteps?.step4 == true && (
          <TutorialBubble
            style={{ position: "absolute" }}
            message={`${t("tuto.step5Part1")}${
              props.state.testCode ? props.state.testCode : ""
            }${t("tuto.step5Part2")}`}
          ></TutorialBubble>
        )}
    </>
  );
};

export default HomeView;
