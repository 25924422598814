import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  tutorialBubble: {
    backgroundColor: "white",
    color: "#333",
    borderRadius: "12px",
    padding: "16px 20px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    maxWidth: 500,
    fontSize: "16px",
    lineHeight: 1.4,
    position: "fixed",
    bottom: "20px",
    left: "20px",
    zIndex: 1000,
    [theme.breakpoints.down("xs")]: {
      maxWidth: 300,
    },
    [theme.breakpoints.down("sm")]: {
      bottom: "80px",
    },
  },

  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    color: "#555",
  },
}));

const TutorialBubble = ({ message, callback }) => {
  const [visible, setVisible] = useState(true);
  const classes = useStyles();

  const handleClose = () => {
    setVisible(false);
    if (callback) {
      callback();
    }
  };

  if (!visible) return null;

  return (
    <div className={classes.tutorialBubble}>
      <CloseIcon className={classes.closeButton} onClick={handleClose} />
      <p dangerouslySetInnerHTML={{ __html: message }}></p>
    </div>
  );
};

TutorialBubble.propTypes = {
  message: PropTypes.string.isRequired,
};

export default TutorialBubble;
