import React from "react";
import TextField from "@material-ui/core/TextField";
import {
  CardMedia,
  Card,
  Typography,
  Button,
  Slider,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from "@material-ui/core";

import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useTranslation } from "react-i18next";

import ImageUtils from "../../../utils/imageUtils";
import DraggableList from "../../components/draggableList";
import ListItemLink from "../../components/listItemLink";
import DialogWarning from "../../components/dialogWarning";
import LoadingView from "../../components/loadingView";
import MainAppBar from "../../components/MainAppBar";
import { GameMode } from "../../../constants/TourConstants";
import BreadcrumbTour from "../../components/breadcrumTour";
import SouthWestIcon from "../../../utils/southWestIcon";
import SelectLocationDialog from "../../components/selectOnMapDialog";
import {
  Delete,
  ExpandMore,
  Map,
  PhoneAndroidOutlined,
  TabletAndroid,
} from "@material-ui/icons";
import UnsavedChangesIndicator from "../../components/unsevedChangesIndicator";
import { Prompt } from "react-router-dom/cjs/react-router-dom.min";
import InfoPopover from "../../../utils/informationButton";
import NoSubscriptionBanner from "../home/components/noSubscriptionBanner";
import DisableToursPopover from "../../../utils/disableToursPopover";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import TutorialBubble from "../../../utils/tutorialBubble";

const TourView = (props) => {
  const zonesItems = props.getZonesItems();
  const { t } = useTranslation();

  function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
      <Tooltip open={open} enterTouchDelay={0} placement="bottom" title={value}>
        {children}
      </Tooltip>
    );
  }

  return (
    <>
      <MainAppBar
        headerText={t("newTour.title")}
        showHomeButton={true}
        showAdminButton={props.state.isAdmin}
      />
      <NoSubscriptionBanner
        role={props.state.role}
        redirectToSelectedPack={null}
        classes={props.classes}
      ></NoSubscriptionBanner>
      <DisableToursPopover
        userOpenedPopover={props.state.isDisableToursOpened}
      />
      <DisableToursPopover
        userOpenedPopover={props.state.isDisableToursOpened}
      />
      <Container className={props.classes.formContainer} maxWidth={false}>
        <UnsavedChangesIndicator show={props.state.unsavedChanges} />
        <Prompt
          when={props.state.unsavedChanges}
          message={t("newTour.unsaved_changes")}
        />
        <BreadcrumbTour
          tourId={props.state.formDatas ? props.state.formDatas.tourId : null}
          zoneId={props.zoneId}
          enigmaId={props.enigmaId}
        />
        <br></br>
        <form
          className={props.classes.form}
          autoComplete="off"
          onSubmit={props.submitForm}
        >
          {props.state.isAdmin ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <FormControlLabel
                style={{ marginLeft: 6 }}
                control={
                  <Checkbox
                    name="mustAddAnalyticBeforeLaunch"
                    checked={props.state.formDatas.mustAddAnalyticBeforeLaunch}
                    onChange={props.updateMustAddAnalyticBeforeLaunch}
                    color="primary"
                  />
                }
                label={t("newTour.blocking_stats_submission")}
              />
              <FormControlLabel
                style={{ marginLeft: 6 }}
                control={
                  <Checkbox
                    name="needDownloadMapTiles"
                    checked={props.state.formDatas.needDownloadMapTiles}
                    onChange={props.updateNeedDownloadMapTiles}
                    color="primary"
                  />
                }
                label={t("newTour.download_map_to_device")}
              />
            </div>
          ) : (
            <></>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginBottom: 10,
            }}
          >
            <FormControlLabel
              style={{ marginLeft: 6 }}
              control={
                <Checkbox
                  name="cluesFree"
                  checked={props.state.formDatas.cluesFree}
                  onChange={props.updateCluesFree}
                  color="primary"
                />
              }
              label={t("newTour.clues_free")}
            />
            <InfoPopover
              title={t("newTour.clues_free")}
              text={t("newTour.free_clues_info")}
            ></InfoPopover>
          </div>
          <Grid
            container
            spacing={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Grid
              item
              xs={12}
              lg={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <Box className={props.classes.textFieldContainer}>
                <TextField
                  required
                  id="filled-required"
                  label={t("newTour.game_title")}
                  defaultValue={props.state.formDatas.title}
                  onChange={props.updateTitle}
                  variant="outlined"
                  size="small"
                  className={props.classes.textFieldLight}
                  style={{ width: "95%" }}
                  key={
                    props.state.tourLoaded ? "titleNotLoaded" : "titleLoaded"
                  }
                />
                <InfoPopover
                  title={t("newTour.game_description")}
                  text={t("newTour.markdown_example_description")}
                  isWhite={true}
                  src="/images/game-tour-markdown.webp"
                ></InfoPopover>
                <TextField
                  required
                  id="filled-required"
                  label={t("newTour.game_description")}
                  defaultValue={props.state.formDatas.subtitle}
                  onChange={props.updateSubtitle}
                  variant="outlined"
                  size="small"
                  multiline={true}
                  minRows={5}
                  inputProps={{ maxLength: 2048 }}
                  className={props.classes.textFieldLight}
                  style={{ width: "95%" }}
                  key={
                    props.state.tourLoaded
                      ? "subtitleNotLoaded"
                      : "subtitleLoaded"
                  }
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "80%",
                  marginBottom: 10,
                }}
              >
                <FormControl
                  variant="outlined"
                  style={{ width: "95%", backgroundColor: "white" }}
                >
                  <InputLabel id="select-response-type">
                    {t("newTour.game_mode")}
                  </InputLabel>
                  <Select
                    labelId="select-response-type"
                    id="select-response-type"
                    value={props.state.formDatas.gameMode}
                    onChange={props.handleGameModeChange}
                  >
                    <MenuItem value={GameMode.MAP_AND_QRCODE}>
                      {t("newTour.map_and_qr_codes")}
                    </MenuItem>
                    <MenuItem value={GameMode.ONLY_QRCODE}>
                      {t("newTour.only_qr_codes")}
                    </MenuItem>
                    <MenuItem value={GameMode.AUTOMATIC_LAUNCH}>
                      {t("newTour.automatic_launch")}
                    </MenuItem>
                  </Select>
                </FormControl>
                {props.state.formDatas.gameMode == GameMode.ONLY_QRCODE ? (
                  <>
                    {" "}
                    <InfoPopover
                      title={t("newTour.only_qr_codes")}
                      text={t("newTour.qr_code_mode_info")}
                    ></InfoPopover>
                  </>
                ) : props.state.formDatas.gameMode ==
                  GameMode.MAP_AND_QRCODE ? (
                  <>
                    <InfoPopover
                      title={t("newTour.map_and_qr_codes")}
                      text={t("newTour.map_qr_code_mode_info")}
                    ></InfoPopover>
                  </>
                ) : props.state.formDatas.gameMode ==
                  GameMode.AUTOMATIC_LAUNCH ? (
                  <>
                    <InfoPopover
                      title={t("newTour.automatic_launch")}
                      text={t("newTour.automatic_mode_info")}
                    ></InfoPopover>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
          <br />
          <Grid
            container
            spacing={6}
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              backgroundColor: "#DCE0EC",
              borderRadius: 12,
              marginLeft: 4,
            }}
          >
            <Grid
              direction="column"
              item
              xs={12}
              lg={4}
              className={props.classes.gridBorder}
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                error={true}
                style={{
                  color: "white",
                  width: "50%",
                }}
                className={props.classes.uploadFile}
                startIcon={<CloudUploadIcon />}
                component="label"
              >
                {t("newTour.background_image")}
                <input
                  type="file"
                  name="file1"
                  onChange={props.backgroundImageSelected}
                  hidden
                  accept=".png,.jpg,.jpeg"
                />
              </Button>
              <Typography
                className={props.classes.imageDimensionsText}
                id="discrete-slider"
                gutterBottom
              >
                {t("newTour.background_image_dimensions_phone")}
                <br />
                {t("newTour.background_image_dimensions_tablet")}
              </Typography>
              {props.state.showBackgroundImageTooBig ? (
                <Typography
                  className={props.classes.imageDimensionsText}
                  style={{ color: "red" }}
                  gutterBottom
                >
                  {t("newTour.background_image_too_big")}
                </Typography>
              ) : (
                <div />
              )}

              <Grid
                item
                xs={12}
                direction="column"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  backgroundColor: "#e6e9f0",
                  borderRadius: 12,
                  padding: 0,
                }}
              >
                <TabContext value={props.state.selectedTab}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      backgroundColor: "#f0f2f7",
                      width: "100%",
                      borderRadius: "12px 12px 0 0",
                    }}
                  >
                    <TabList
                      style={{ width: "100%" }}
                      onChange={(event, newValue) =>
                        props.setSelectedTab(newValue)
                      }
                    >
                      <Tab
                        style={{ width: "50%", fontSize: 7 }}
                        icon={
                          <PhoneAndroidOutlined
                            style={{ height: 15, width: 15 }}
                          />
                        }
                        label={
                          <span style={{ fontSize: "10px" }}>
                            {t("newTour.telephone")}
                          </span>
                        }
                        value="1"
                      />
                      <Tab
                        style={{ width: "50%" }}
                        icon={
                          <TabletAndroid style={{ height: 15, width: 15 }} />
                        }
                        label={
                          <span style={{ fontSize: "10px" }}>
                            {t("newTour.tablet")}
                          </span>
                        }
                        value="2"
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <Container className={props.classes.cardContainer}>
                      <Typography
                        className={props.classes.imageInfos}
                        gutterBottom
                      >
                        {t("newTour.image_display_before_game_phone")}
                      </Typography>
                      <Card className={props.classes.imageCardBackground}>
                        <CardMedia
                          className={props.classes.imageCardMedia}
                          image={
                            ImageUtils.getImageUrlFromFile(
                              props.state.formDatas.backgroundImageFile
                            )
                              ? ImageUtils.getImageUrlFromFile(
                                  props.state.formDatas.backgroundImageFile
                                )
                              : props.state.formDatas.backgroundImageUrl
                          }
                          title="BackgroundImage"
                        />
                      </Card>
                    </Container>
                    <Container className={props.classes.cardContainer}>
                      <Typography
                        className={props.classes.imageInfos}
                        gutterBottom
                      >
                        {t("newTour.image_display_in_list_phone")}
                      </Typography>
                      <Card className={props.classes.imageCardBackgroundInList}>
                        <CardMedia
                          className={props.classes.imageCardMedia}
                          image={
                            ImageUtils.getImageUrlFromFile(
                              props.state.formDatas.backgroundImageFile
                            )
                              ? ImageUtils.getImageUrlFromFile(
                                  props.state.formDatas.backgroundImageFile
                                )
                              : props.state.formDatas.backgroundImageUrl
                          }
                          title="BackgroundImage"
                        />
                      </Card>
                    </Container>
                  </TabPanel>
                  <TabPanel value="2">
                    <Container className={props.classes.cardContainer}>
                      <Typography
                        className={props.classes.imageInfos}
                        gutterBottom
                      >
                        {t("newTour.image_display_before_game_tablet")}
                      </Typography>
                      <Card className={props.classes.imageCardBackgroundTab}>
                        <CardMedia
                          className={props.classes.imageCardMedia}
                          image={
                            ImageUtils.getImageUrlFromFile(
                              props.state.formDatas.backgroundImageFile
                            )
                              ? ImageUtils.getImageUrlFromFile(
                                  props.state.formDatas.backgroundImageFile
                                )
                              : props.state.formDatas.backgroundImageUrl
                          }
                          title="BackgroundImage"
                        />
                      </Card>
                    </Container>
                    <Container className={props.classes.cardContainer}>
                      <Typography
                        className={props.classes.imageInfos}
                        gutterBottom
                      >
                        {t("newTour.image_display_in_list_tablet")}
                      </Typography>
                      <Card
                        className={props.classes.imageCardBackgroundInListTab}
                      >
                        <CardMedia
                          className={props.classes.imageCardMedia}
                          image={
                            ImageUtils.getImageUrlFromFile(
                              props.state.formDatas.backgroundImageFile
                            )
                              ? ImageUtils.getImageUrlFromFile(
                                  props.state.formDatas.backgroundImageFile
                                )
                              : props.state.formDatas.backgroundImageUrl
                          }
                          title="BackgroundImage"
                        />
                      </Card>
                    </Container>
                  </TabPanel>
                </TabContext>
              </Grid>

              <Grid
                item
                xs={12}
                direction="row"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              ></Grid>
            </Grid>
            {props.state.formDatas.gameMode == GameMode.ONLY_QRCODE ||
            props.state.formDatas.gameMode == GameMode.AUTOMATIC_LAUNCH ? (
              <></>
            ) : (
              <Grid
                direction="column"
                className={props.classes.gridBorder}
                item
                xs={12}
                lg={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  className={props.classes.uploadFile}
                  style={{
                    color: "white",
                    width: "50%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  startIcon={<CloudUploadIcon />}
                  component="label"
                >
                  {t("newTour.start_point_image")}
                  <input
                    type="file"
                    name="file3"
                    onChange={props.annotationImageSelected}
                    hidden
                    accept=".png,.jpg,.jpeg"
                  />
                </Button>
                <Typography
                  className={props.classes.imageDimensionsText}
                  gutterBottom
                >
                  {t("newTour.start_point_image_dimensions")}
                </Typography>
                {/* <Typography className={props.classes.imageInfos} gutterBottom>
                  {t("newTour.image_display_phone_tablet")}
                </Typography> */}
                {props.state.showAnnotationImageTooBig ? (
                  <Typography
                    className={props.classes.imageDimensionsText}
                    style={{ color: "red" }}
                    gutterBottom
                  >
                    {t("newTour.image_size_limit_500kb")}
                  </Typography>
                ) : (
                  <div />
                )}
                <Box>
                  <Card className={props.classes.imageCardAnnotation}>
                    <CardMedia
                      className={props.classes.imageCardMedia}
                      image={
                        ImageUtils.getImageUrlFromFile(
                          props.state.formDatas.annotationImageFile
                        )
                          ? ImageUtils.getImageUrlFromFile(
                              props.state.formDatas.annotationImageFile
                            )
                          : props.state.formDatas.annotationImageUrl
                      }
                      title="AnnotationImage"
                    />
                  </Card>
                </Box>
                <br />

                <br />
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  className={props.classes.alignCenterXs}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2} // Ajoute de l'espace entre les éléments
                  >
                    {" "}
                    <TextField
                      required
                      id="standard-helperText"
                      label={t("newTour.start_point_name")}
                      defaultValue={props.state.formDatas.homeAnnotationName}
                      onChange={props.updateHomeAnnotationName}
                      variant="outlined"
                      size="small"
                      className={props.classes.textfieldWhite}
                      key={
                        props.state.tourLoaded
                          ? "homeAnnottationNameLoaded"
                          : "homeAnnottationNameNotLoaded"
                      }
                    />
                    <br />
                  </Grid>

                  <Grid container spacing={2} flexDirection="column" display>
                    {/* <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    > */}
                    {/* <Box style={{ display: "flex", flexDirection: "row" }}> */}
                    <Grid item xs={12}>
                      <Button
                        style={{ width: "100%" }}
                        onClick={() => props.openMapDialog("HOME")}
                        className={props.classes.mapButton}
                        startIcon={<Map />}
                      >
                        {t("newTour.select_pos")}
                      </Button>
                      {/* </Box> */}
                      {/* </Box> */}
                    </Grid>
                    <Grid item xs={12}>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Typography>{t("newTour.show_coords")}</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                          <TextField
                            id="filled-required"
                            label={t("newTour.latitude")}
                            onChange={props.updateHomeCoordsLatitude}
                            value={
                              props.state.formDatas.homeCoords.latitude != 0
                                ? props.state.formDatas.homeCoords.latitude
                                : ""
                            }
                            className={props.classes.textfieldWhite}
                            variant="outlined"
                            size="small"
                            style={{ width: "100px" }}
                            inputProps={{
                              className: props.classes.input,
                              pattern: "-?(([1-9][0-9]*)|0)?(\\.[0-9]*)?",
                            }}
                            key={
                              props.state.tourLoaded
                                ? "latHomeCoordsNotLoaded"
                                : "latHomeCoordsLoaded"
                            }
                          />
                          <TextField
                            id="filled-required"
                            label={t("newTour.longitude")}
                            onChange={props.updateHomeCoordsLongitude}
                            value={
                              props.state.formDatas.homeCoords.longitude != 0
                                ? props.state.formDatas.homeCoords.longitude
                                : ""
                            }
                            className={props.classes.textfieldWhite}
                            style={{ width: "100px" }}
                            variant="outlined"
                            size="small"
                            key={
                              props.state.tourLoaded
                                ? "lonHomeCoordsNotLoaded"
                                : "lonHomeCoordsLoaded"
                            }
                          />
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                  <Grid direction="row">
                    {" "}
                    <Box style={{ display: "flex", flexDirection: "row" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="redirectToHomeWhileFinished"
                            checked={
                              props.state.formDatas.redirectToHomeWhileFinished
                            }
                            onChange={props.updateRedirectToHomeWhileFinished}
                            color="primary"
                          />
                        }
                        label={t("newTour.redirect_to_home")}
                      />
                      <InfoPopover
                        title={t("newTour.redirect_to_title")}
                        text={t("newTour.redirect_to_home_info")}
                        src="/images/fin-de-partie-avec-redirect.webp"
                      ></InfoPopover>
                    </Box>
                    <br />
                  </Grid>
                </Grid>
                <br />
                <Divider style={{ width: "100%" }} />
                <br />

                <Typography
                  className={props.classes.title}
                  align="inherit"
                  gutterBottom
                >
                  {t("newTour.map_limits")}
                </Typography>
                <Grid
                  container
                  xs={12}
                  flexDirection="column"
                  className={props.classes.alignCenterXs}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid
                    xs={12}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{ marginBottom: 14 }}
                  >
                    <Typography className={props.classes.headerLocations}>
                      {t("newTour.map_northeast_limit")}
                    </Typography>
                    {/* <Box style={{ display: "flex", flexDirection: "column" }}> */}
                    <Button
                      style={{ width: "100%" }}
                      onClick={() => props.openMapDialog("NE")}
                      className={props.classes.mapButton}
                      startIcon={<Map />}
                    >
                      {t("newTour.select_pos")}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>{t("newTour.show_coords")}</Typography>
                      </AccordionSummary>

                      <AccordionDetails>
                        <TextField
                          id="filled-required"
                          label={t("newTour.latitude")}
                          value={
                            props.state.formDatas.cameraBoundsNE.latitude != 0
                              ? props.state.formDatas.cameraBoundsNE.latitude
                              : ""
                          }
                          className={props.classes.textfieldWhite}
                          onChange={props.updateCameraBoundsNELatitude}
                          variant="outlined"
                          style={{ width: "100px" }}
                          size="small"
                          inputProps={{
                            className: props.classes.input,
                            pattern: "-?(([1-9][0-9]*)|0)?(\\.[0-9]*)?",
                          }}
                          key={
                            props.state.tourLoaded
                              ? "latCamNELoaded"
                              : "latCamNENotLoaded"
                          }
                        />{" "}
                        <TextField
                          id="filled-required"
                          label={t("newTour.longitude")}
                          // helperText="Ces coordonnées définissent les limites de la map afficher à l'utilisateur."
                          value={
                            props.state.formDatas.cameraBoundsNE.longitude != 0
                              ? props.state.formDatas.cameraBoundsNE.longitude
                              : ""
                          }
                          className={props.classes.textfieldWhite}
                          style={{ width: "100px" }}
                          onChange={props.updateCameraBoundsNELongitude}
                          variant="outlined"
                          size="small"
                          inputProps={{
                            className: props.classes.input,
                            pattern: "-?(([1-9][0-9]*)|0)?(\\.[0-9]*)?",
                          }}
                          key={
                            props.state.tourLoaded
                              ? "lonCamNELoaded"
                              : "lonCamNENotLoaded"
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                    {/* </Box> */}
                  </Grid>

                  <Grid
                    container
                    xs={12}
                    flexDirection="column"
                    className={props.classes.alignCenterXs}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid
                      xs={12}
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      style={{ marginBottom: 14 }}
                    >
                      {" "}
                      <Typography
                        style={{ marginTop: 12 }}
                        className={props.classes.headerLocations}
                      >
                        {t("newTour.map_southwest_limit")}
                      </Typography>
                      <Button
                        style={{ width: "100%" }}
                        onClick={() => props.openMapDialog("SW")}
                        className={props.classes.mapButton}
                        startIcon={<Map />}
                      >
                        {t("newTour.select_pos")}
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Typography>{t("newTour.show_coords")}</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Box
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <TextField
                              id="filled-required"
                              label={t("newTour.latitude")}
                              value={
                                props.state.formDatas.cameraBoundsSW.latitude !=
                                0
                                  ? props.state.formDatas.cameraBoundsSW
                                      .latitude
                                  : ""
                              }
                              className={props.classes.textfieldWhite}
                              style={{ width: "100px" }}
                              onChange={props.updateCameraBoundsSWLatitude}
                              variant="outlined"
                              size="small"
                              inputProps={{
                                className: props.classes.input,
                                pattern: "-?(([1-9][0-9]*)|0)?(\\.[0-9]*)?",
                              }}
                              key={
                                props.state.tourLoaded
                                  ? "latCamSWLoaded"
                                  : "latCamSWNotLoaded"
                              }
                            />
                            <TextField
                              id="filled-required"
                              label={t("newTour.longitude")}
                              value={
                                props.state.formDatas.cameraBoundsSW
                                  .longitude != 0
                                  ? props.state.formDatas.cameraBoundsSW
                                      .longitude
                                  : ""
                              }
                              style={{ width: "100px" }}
                              onChange={props.updateCameraBoundsSWLongitude}
                              variant="outlined"
                              size="small"
                              className={props.classes.textfieldWhite}
                              inputProps={{
                                className: props.classes.input,
                                pattern: "-?(([1-9][0-9]*)|0)?(\\.[0-9]*)?",
                              }}
                              key={
                                props.state.tourLoaded
                                  ? "lonCamSWLoaded"
                                  : "lonCamSWNotLoaded"
                              }
                            />
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                </Grid>
                <Typography className={props.classes.helperText}>
                  {t("newTour.map_limits_info")}
                </Typography>
              </Grid>
            )}
            <Grid
              direction="column"
              item
              xs={12}
              lg={4}
              style={{
                display: "flex",
                alignItems: "center",
              }}
              className={props.classes.alignCenterXs}
            >
              <Typography
                id="discrete-slider"
                style={{ fontWeight: 700 }}
                gutterBottom
              >
                {t("newTour.game_duration")}
              </Typography>
              <TextField
                required
                id="filled-required"
                label="Minutes"
                variant="outlined"
                size="small"
                type="number"
                value={props.state.formDatas.timer}
                onChange={props.updateTimer}
                className={props.classes.textfieldWhite}
                InputProps={{
                  inputProps: {
                    max: 600,
                    min: 0,
                  },
                }}
                key={props.state.zoneLoaded ? "timerNotLoaded" : "timerLoaded"}
              />
              <br />
              <Divider style={{ width: "100%" }} />
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  style={{ fontSize: 20, fontWeight: 800, color: "#48506B" }}
                  gutterBottom
                >
                  {t("newEnigma.optionnel")}
                </Typography>
              </Box>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Button
                  variant="contained"
                  error={true}
                  style={{
                    color: "white",
                    width: "50%",
                  }}
                  className={props.classes.uploadFile}
                  startIcon={<CloudUploadIcon />}
                  component="label"
                >
                  {t("newTour.music_file")}
                  <input
                    type="file"
                    name="file1"
                    onChange={props.backgroundMusicSelected}
                    hidden
                    accept=".mp3,.wav"
                  />
                </Button>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={(e) => {
                    props.deleteMusic();
                  }}
                >
                  <Delete style={{ color: "red" }} />
                </IconButton>
              </Box>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {(props.state.formDatas.musicFileName ||
                  props.state.formDatas.musicFile) && (
                  <Typography
                    className={props.classes.imageDimensionsText}
                    id="discrete-slider"
                    gutterBottom
                    style={{ textAlign: "center" }}
                  >
                    {props.state.formDatas.musicFile?.name != null
                      ? props.state.formDatas.musicFile?.name
                      : props.state.formDatas.musicFileName}
                  </Typography>
                )}
              </Box>
              {/* <Typography
                className={props.classes.imageDimensionsText}
                id="discrete-slider"
                gutterBottom
              >
                {t("newTour.music_file_size")}
              </Typography> */}
              {props.state.showMusicFileTooBig ? (
                <Typography
                  className={props.classes.imageDimensionsText}
                  style={{ color: "red" }}
                  gutterBottom
                >
                  {t("newTour.music_file_too_big")}
                </Typography>
              ) : (
                <div />
              )}
            </Grid>
          </Grid>
          <br></br> <br />
          <Box className={props.classes.listContainer}>
            <div className={props.classes.list}>
              {props.state.zonesLoaded ? (
                <DraggableList
                  items={zonesItems}
                  onDragEnd={props.onDragEnd}
                  onDelete={props.openDeleteZoneDialog}
                />
              ) : (
                <div></div>
              )}
              <Box className={props.classes.buttonListPlus}>
                <ListItemLink
                  to={`/zone?tourId=${props.state.formDatas.tourId}`}
                  key={1000}
                  primary={t("newTour.new_zone")}
                  icon={<AddCircleOutlineIcon />}
                  deleteButon={false}
                  disabled={
                    props.state.formDatas.tourId &&
                    props.state.role !== "NO_SUBSCRIBTION" &&
                    !props.state.formDatas?.disabled
                      ? false
                      : true
                  }
                />
              </Box>
            </div>
          </Box>
          <DialogWarning
            open={props.state.dialogWaringOpened}
            primaryText={t("newTour.delete_zone_warning")}
            secondaryText={t("newTour.delete_zone_confirm")}
            primaryAction={props.deleteZone}
            closeAction={props.closeDeleteZoneDialog}
            idItem={props.state.zoneIdToDelete}
            primaryButtonText={t("parcours_list.delete_button")}
            secondaryButtonText={t("parcours_list.cancel_button")}
          />
          <LoadingView
            className={props.classes.loadingView}
            open={props.state.loadingViewOpened}
          />
          <br />
          {props.state.showError ? (
            <Alert severity="error">{props.state.errorMessage}</Alert>
          ) : (
            <div />
          )}
          {props.state.showSuccess ? (
            <Alert severity="success">{props.state.successMessage}</Alert>
          ) : (
            <div />
          )}
          <br />
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              className={`${props.classes.saveButton} ${
                props.state.role === "NO_SUBSCRIBTION" ||
                props.state.formDatas?.disabled
                  ? props.classes.buttonDisabled
                  : ""
              }`}
              type="submit"
            >
              {props.state.editing ? (
                <div>{t("newTour.save")}</div>
              ) : (
                <div>{t("newTour.add")}</div>
              )}
            </Button>
          </Box>
        </form>
      </Container>
      {props.state.tutorialSteps?.step2 == false && (
        <TutorialBubble
          style={{ position: "absolute" }}
          message={t("tuto.step2")}
        ></TutorialBubble>
      )}
      {props.state.tutorialSteps?.step5 == false &&
        props.state.tutorialSteps?.step1 == true &&
        props.state.tutorialSteps?.step2 == true &&
        props.state.tutorialSteps?.step3 == true &&
        props.state.tutorialSteps?.step4 == true && (
          <TutorialBubble
            style={{ position: "absolute" }}
            message={`${t("tuto.step5Part1")}${
              props.state.testCode ? props.state.testCode : ""
            }${t("tuto.step5Part2")}`}
          ></TutorialBubble>
        )}
      <SelectLocationDialog
        zones={props.state.zones}
        type={props.mapType}
        lat={props.showLatOnMap}
        lng={props.showLngOnMap}
        open={props.mapDialogOpen}
        onClose={() => props.closeMapDialog()}
        coordsNE={props.state.formDatas.cameraBoundsNE}
        coordsSW={props.state.formDatas.cameraBoundsSW}
        onLocationSelected={(location, type) => {
          if (type === "SW") {
            props.setCameraBoundsSWLocation(location);
          }
          if (type === "NE") {
            props.setCameraBoundsNELocation(location);
          }
          if (type === "HOME") {
            props.setHomeLocation(location);
          }
        }}
      />{" "}
    </>
  );
};

export default TourView;
