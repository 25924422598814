import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import qs from "qs";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import QrIcon from "@material-ui/icons/CropFree";

import ApiUser from "../../../API/ApiUser";
import ApiTour from "../../../API/ApiTour";
import ApiZone from "../../../API/ApiZone";
import { getSizeInMb } from "../../../utils/sizeInMb";
import { GameMode } from "../../../constants/TourConstants";
import { ZoneMode } from "../../../constants/TourConstants";
import { withTranslation } from "react-i18next";

import styles from "./newTourStyles";
import TourView from "./newTourView";
import ApiAdmin from "../../../API/ApiAdmin";

class NewTour extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdmin: false,
      role: null,
      mapDialogOpen: false,
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      showMusicFileTooBig: false,
      showBackgroundImageTooBig: false,
      showAnnotationImageTooBig: false,
      showInGameBackgroundImageTooBig: false,
      tourLoaded: false,
      editing: false,
      zonesOrderIdsChanged: false,
      dialogWaringOpened: false,
      loadingViewOpened: false,
      unsavedChanges: false,
      zoneIdToDelete: -1,
      mapType: "",
      showLatOnMap: null,
      showLngOnMap: null,
      selectedTab: "1",
      tutorialSteps: null,
      testCode: null,
      zones: [],
      zonesDatas: [
        {
          name: "",
          id: 9999,
          orderId: 0,
          isValid: false,
          isActive: true,
          zoneMode: ZoneMode,
        },
      ],
      formDatas: {
        clientUserId: null,
        tourId: null,
        disabled: null,
        title: "",
        subtitle: "",
        gameMode: GameMode.MAP_AND_QRCODE,
        homeAnnotationName: "",
        mustAddAnalyticBeforeLaunch: false,
        redirectToHomeWhileFinished: false,
        cluesFree: false,
        needDownloadMapTiles: false,
        musicFile: null,
        musicFileDeleted: false,
        backgroundImageFile: null,
        annotationImageFile: null,
        inGameBackgroundImageFile: null,
        backgroundImage: null,
        annotationImage: null,
        inGameBackgroundImage: null,
        backgroundImageUrl: "",
        annotationImageUrl: "",
        inGameBackgroundImageUrl: "",
        homeName: "",
        timer: 60,
        homeCoords: {
          longitude: 0,
          latitude: 0,
        },
        cameraBoundsNE: {
          longitude: 0,
          latitude: 0,
        },
        cameraBoundsSW: {
          longitude: 0,
          latitude: 0,
        },
      },
    };
    this.setSelectedTab = this.setSelectedTab.bind(this);
    this.backgroundMusicSelected = this.backgroundMusicSelected.bind(this);
    this.backgroundImageSelected = this.backgroundImageSelected.bind(this);
    this.inGameBackgroundImageSelected =
      this.inGameBackgroundImageSelected.bind(this);
    this.annotationImageSelected = this.annotationImageSelected.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.deleteMusic = this.deleteMusic.bind(this);

    this.updateTitle = this.updateTitle.bind(this);
    this.updateSubtitle = this.updateSubtitle.bind(this);
    this.updateHomeAnnotationName = this.updateHomeAnnotationName.bind(this);
    this.updateRedirectToHomeWhileFinished =
      this.updateRedirectToHomeWhileFinished.bind(this);
    this.updateNeedDownloadMapTiles =
      this.updateNeedDownloadMapTiles.bind(this);
    this.updateMustAddAnalyticBeforeLaunch =
      this.updateMustAddAnalyticBeforeLaunch.bind(this);
    this.updateHomeName = this.updateHomeName.bind(this);
    this.updateTimer = this.updateTimer.bind(this);
    this.updateHomeCoordsLongitude = this.updateHomeCoordsLongitude.bind(this);
    this.updateHomeCoordsLatitude = this.updateHomeCoordsLatitude.bind(this);
    this.updateCameraBoundsNELongitude =
      this.updateCameraBoundsNELongitude.bind(this);
    this.updateCameraBoundsNELatitude =
      this.updateCameraBoundsNELatitude.bind(this);
    this.updateCameraBoundsSWLongitude =
      this.updateCameraBoundsSWLongitude.bind(this);
    this.updateCameraBoundsSWLatitude =
      this.updateCameraBoundsSWLatitude.bind(this);
    this.handleGameModeChange = this.handleGameModeChange.bind(this);
    this.updateCluesFree = this.updateCluesFree.bind(this);

    this.onDragEnd = this.onDragEnd.bind(this);
    this.deleteZone = this.deleteZone.bind(this);
    this.openDeleteZoneDialog = this.openDeleteZoneDialog.bind(this);
    this.closeDeleteZoneDialog = this.closeDeleteZoneDialog.bind(this);

    this.setHomeLocation = this.setHomeLocation.bind(this);
    this.setCameraBoundsNELocation = this.setCameraBoundsNELocation.bind(this);
    this.setCameraBoundsSWLocation = this.setCameraBoundsSWLocation.bind(this);
    this.closeMapDialog = this.closeMapDialog.bind(this);
    this.openMapDialog = this.openMapDialog.bind(this);
  }

  componentDidMount() {
    const { t } = this.props;
    window.addEventListener("beforeunload", this.handleBeforeUnload);

    this.getRole();

    let id = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).id;

    id = parseInt(id);

    this.setStateIsAdmin();

    let clientUserId = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).clientUserId;

    clientUserId = parseInt(clientUserId);
    if (!isNaN(clientUserId)) {
      this.setState((prevState) => ({
        ...prevState,

        formDatas: {
          ...prevState.formDatas,
          clientUserId:
            clientUserId != null && clientUserId != "" ? clientUserId : null,
        },
      }));
    }

    if (!id) return;

    this.setState((prevState) => ({
      ...prevState,

      formDatas: {
        ...prevState.formDatas,
        tourId: id,
      },
    }));

    ApiTour.getTour(id).then((tourDatas) => {
      if (!tourDatas || tourDatas.status !== 200) {
        this.setState((prevState) => ({
          ...prevState,
          showError: true,
          errorMessage: t("newTour.error_retrieving_tour"),
          showSuccess: false,
          successMessage: "",
        }));

        return;
      }
      this.getFirstTestCode(id);
      this.setState((prevState) => ({
        ...prevState,
        editing: true,
      }));

      this.setFormDatas(tourDatas.data);
    });

    ApiZone.getZones(id).then((zones) => {
      let zonesDatas = [];

      if (zones.status !== 200 && zones.status !== 204) {
        this.setState((prevState) => ({
          ...prevState,
          showError: true,
          errorMessage: t("newTour.error_retrieving_zones"),
          showSuccess: false,
          successMessage: "",
        }));

        return;
      }

      if (zones.status == 204) return;

      zones.data.forEach((elem, i) => {
        zonesDatas.push({
          name: elem.name,
          id: elem.id,
          isValid: elem.isValid,
          isActive: elem.isActive,
          orderId: elem.orderId,
          zoneMode: elem.zoneMode,
        });
      });

      this.setState((prevState) => ({
        ...prevState,
        zonesDatas: zonesDatas,
        zones: zones.data,
        zonesLoaded: true,
      }));
    });
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  }

  async getFirstTestCode(tourId) {
    const res = await ApiTour.getFirstTestCode(tourId);
    this.setState((prevState) => ({
      ...prevState,
      testCode: res.data?.code,
    }));
  }

  setSelectedTab = (value) => {
    console.log("selected tab ", value);
    this.setState((prevState) => ({
      ...prevState,
      selectedTab: value,
    }));
  };

  async getRole() {
    let response = await ApiUser.getAccount();

    if (response.status == 200) {
      this.setState((prevState) => ({
        ...prevState,
        role: response.data.role,
        tutorialSteps: response.data.tutorialSteps,
      }));
    }
  }

  handleBeforeUnload = (event) => {
    const { t } = this.props;
    if (this.state.unsavedChanges) {
      const message = t("newTour.modifications_not_saved");
      event.returnValue = message;
      return message;
    }
  };

  async setHomeLocation(location) {
    if (!location || !location.lat || !location.lng) return;

    this.setState((prevState) => ({
      unsavedChanges: true,

      formDatas: {
        ...prevState.formDatas,
        homeCoords: {
          ...prevState.formDatas.homeCoords,
          longitude: location.lng,
          latitude: location.lat,
        },
      },
    }));
  }

  async setCameraBoundsNELocation(location) {
    if (!location || !location.lat || !location.lng) return;

    this.setState(
      (prevState) => ({
        ...prevState,
        unsavedChanges: true,

        formDatas: {
          ...prevState.formDatas,
          cameraBoundsNE: {
            ...prevState.formDatas.cameraBoundsNE,
            longitude: location.lng,
            latitude: location.lat,
          },
        },
      }),
      () => {}
    );
  }

  async setCameraBoundsSWLocation(location) {
    if (!location || !location.lat || !location.lng) return;

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,

      formDatas: {
        ...prevState.formDatas,
        cameraBoundsSW: {
          ...prevState.formDatas.cameraBoundsSW,
          longitude: location.lng,
          latitude: location.lat,
        },
      },
    }));
  }

  closeMapDialog() {
    this.setState((prevState) => ({
      ...prevState,
      mapDialogOpen: false,
    }));
  }

  openMapDialog(type) {
    let lat = null;
    let lng = null;
    if (type === "HOME") {
      lat = this.state.formDatas.homeCoords.latitude;
      lng = this.state.formDatas.homeCoords.longitude;
    } else if (type === "NE") {
      lat = this.state.formDatas.cameraBoundsNE.latitude;
      lng = this.state.formDatas.cameraBoundsNE.longitude;
    } else if (type === "SW") {
      lat = this.state.formDatas.cameraBoundsSW.latitude;
      lng = this.state.formDatas.cameraBoundsSW.longitude;
    }

    console.log("lat dans newTourComp ", lat);
    console.log("lng dans newTourComp ", lng);

    this.setState(
      (prevState) => ({
        ...prevState,

        mapDialogOpen: true,
        mapType: type,
        showLatOnMap: lat,
        showLngOnMap: lng,
      }),
      () => {}
    );
  }

  async setStateIsAdmin() {
    let response = await ApiUser.isAdmin();

    if (response.status == 200) {
      this.setState((prevState) => ({
        ...prevState,
        isAdmin: response.data.isAdmin,
      }));
    }
  }

  setFormDatas(tour) {
    console.log("tour  : ", tour);
    this.setState((prevState) => ({
      ...prevState,
      tourLoaded: true,
      formDatas: {
        ...prevState.formDatas,
        tourId: tour.id,
        disabled: tour.disabled,
        title: tour.title,
        subtitle: tour.subtitle,
        homeAnnotationName: tour.homeAnnotationName,
        redirectToHomeWhileFinished: tour.redirectToHomeWhileFinished,
        needDownloadMapTiles: tour.needDownloadMapTiles,
        mustAddAnalyticBeforeLaunch: tour.mustAddAnalyticBeforeLaunch,
        cluesFree: tour.cluesFree,
        backgroundImageUrl: tour.backgroundImage?.signedUrl,
        annotationImageUrl: tour.annotationImage?.signedUrl,
        inGameBackgroundImageUrl: tour.inGameBackgroundImage?.signedUrl,
        gameMode: tour.gameMode,
        homeName: tour.homeName,
        timer: tour.timer,
        musicFileName: tour.music?.name,
        homeCoords: {
          longitude: tour.homeCoordsLongitude,
          latitude: tour.homeCoordsLatitude,
        },
        cameraBoundsNE: {
          longitude: tour.cameraBoundsNELongitude,
          latitude: tour.cameraBoundsNELatitude,
        },
        cameraBoundsSW: {
          longitude: tour.cameraBoundsSWLongitude,
          latitude: tour.cameraBoundsSWLatitude,
        },
      },
    }));
  }

  handleGameModeChange(event, value) {
    event.preventDefault();

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,

        gameMode: event.target.value,
      },
    }));
  }

  updateTitle(event) {
    event.preventDefault();

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,

      formDatas: {
        ...prevState.formDatas,
        title: event.target.value,
      },
    }));
  }
  updateSubtitle(event) {
    event.preventDefault();

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,

      formDatas: {
        ...prevState.formDatas,
        subtitle: event.target.value,
      },
    }));
  }
  updateHomeAnnotationName(event) {
    event.preventDefault();

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,

      formDatas: {
        ...prevState.formDatas,
        homeAnnotationName: event.target.value,
      },
    }));
  }

  updateRedirectToHomeWhileFinished(event) {
    event.preventDefault();
    const value = event.target.checked;

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        redirectToHomeWhileFinished: value,
      },
    }));
  }

  updateCluesFree(event) {
    event.preventDefault();
    const value = event.target.checked;

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        cluesFree: value,
      },
    }));
  }

  updateNeedDownloadMapTiles(event) {
    const value = event.target.checked;

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        needDownloadMapTiles: value,
      },
    }));
  }

  updateMustAddAnalyticBeforeLaunch(event) {
    const value = event.target.checked;

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      formDatas: {
        ...prevState.formDatas,
        mustAddAnalyticBeforeLaunch: value,
      },
    }));
  }

  updateHomeName(event) {
    event.preventDefault();

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,

      formDatas: {
        ...prevState.formDatas,
        homeName: event.target.value,
      },
    }));
  }

  updateTimer(event, value) {
    event.preventDefault();

    console.log("timer value : ", value);
    console.log("timer event : ", event.target.value);

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,

      formDatas: {
        ...prevState.formDatas,
        timer: parseInt(event.target.value),
      },
    }));
  }

  updateHomeCoordsLongitude(event) {
    event.preventDefault();

    let value = event.target.value;

    if (isNaN(parseFloat(value))) {
      value = 0;
    } else {
      value = parseFloat(value);
    }
    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,

      formDatas: {
        ...prevState.formDatas,
        homeCoords: {
          ...prevState.formDatas.homeCoords,
          longitude: value,
        },
      },
    }));
  }

  updateHomeCoordsLatitude(event) {
    event.preventDefault();

    let value = event.target.value;

    if (isNaN(parseFloat(value))) {
      value = 0;
    } else {
      value = parseFloat(value);
    }

    console.log("value ", value);

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,

      formDatas: {
        ...prevState.formDatas,
        homeCoords: {
          ...prevState.formDatas.homeCoords,
          latitude: value,
        },
      },
    }));
  }

  updateCameraBoundsNELongitude(event) {
    event.preventDefault();

    let value = event.target.value;

    if (isNaN(parseFloat(value))) {
      value = 0;
    } else {
      value = parseFloat(value);
    }

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,

      formDatas: {
        ...prevState.formDatas,
        cameraBoundsNE: {
          ...prevState.formDatas.cameraBoundsNE,
          longitude: value,
        },
      },
    }));
  }

  updateCameraBoundsNELatitude(event) {
    event.preventDefault();

    let value = event.target.value;

    if (isNaN(parseFloat(value))) {
      value = 0;
    } else {
      value = parseFloat(value);
    }

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,

      formDatas: {
        ...prevState.formDatas,
        cameraBoundsNE: {
          ...prevState.formDatas.cameraBoundsNE,
          latitude: value,
        },
      },
    }));
  }

  updateCameraBoundsSWLongitude(event) {
    event.preventDefault();

    let value = event.target.value;

    if (isNaN(parseFloat(value))) {
      value = 0;
    } else {
      value = parseFloat(value);
    }

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,

      formDatas: {
        ...prevState.formDatas,
        cameraBoundsSW: {
          ...prevState.formDatas.cameraBoundsSW,
          longitude: value,
        },
      },
    }));
  }

  updateCameraBoundsSWLatitude(event) {
    event.preventDefault();

    let value = event.target.value;

    if (isNaN(parseFloat(value))) {
      value = 0;
    } else {
      value = parseFloat(value);
    }

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,

      formDatas: {
        ...prevState.formDatas,
        cameraBoundsSW: {
          ...prevState.formDatas.cameraBoundsSW,
          latitude: value,
        },
      },
    }));
  }

  inGameBackgroundImageSelected(event) {
    event.preventDefault();

    if (!event.target.files || !event.target.files[0]) {
      return;
    }

    let fileSizeMb = getSizeInMb(event.target.files[0].size);

    if (fileSizeMb > 15) {
      this.setState((prevState) => ({
        ...prevState,
        showInGameBackgroundImageTooBig: true,
      }));

      return;
    }

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,

      showInGameBackgroundImageTooBig: false,
      formDatas: {
        ...prevState.formDatas,
        inGameBackgroundImageFile: event.target.files[0],
      },
    }));
  }

  deleteMusic() {
    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      showMusicFileTooBig: false,
      formDatas: {
        ...prevState.formDatas,
        musicFile: null,
        musicFileDeleted: true,
        musicFileName: null,
      },
    }));
  }

  backgroundMusicSelected(event) {
    event.preventDefault();

    if (!event.target.files || !event.target.files[0]) {
      return;
    }

    let fileSizeMb = getSizeInMb(event.target.files[0].size);

    if (fileSizeMb > 10) {
      this.setState((prevState) => ({
        ...prevState,
        showMusicFileTooBig: true,
      }));

      return;
    }

    console.log("musicFile ", event.target.files[0]);

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      showMusicFileTooBig: false,
      formDatas: {
        ...prevState.formDatas,
        musicFile: event.target.files[0],
        musicFileDeleted: false,
      },
    }));
  }

  backgroundImageSelected(event) {
    event.preventDefault();

    if (!event.target.files || !event.target.files[0]) {
      return;
    }

    let fileSizeMb = getSizeInMb(event.target.files[0].size);

    if (fileSizeMb > 15) {
      this.setState((prevState) => ({
        ...prevState,
        showBackgroundImageTooBig: true,
      }));

      return;
    }

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,

      showBackgroundImageTooBig: false,
      formDatas: {
        ...prevState.formDatas,
        backgroundImageFile: event.target.files[0],
      },
    }));
  }

  annotationImageSelected(event) {
    event.preventDefault();

    if (!event.target.files || !event.target.files[0]) {
      return;
    }

    let fileSizeMb = getSizeInMb(event.target.files[0].size);

    if (fileSizeMb > 15) {
      this.setState((prevState) => ({
        ...prevState,
        showAnnotationImageTooBig: true,
      }));

      return;
    }

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,

      showAnnotationImageTooBig: false,
      formDatas: {
        ...prevState.formDatas,
        showAnnotationImageTooBig: false,
        annotationImageFile: event.target.files[0],
      },
    }));
  }

  async isAdmin() {
    let response = await ApiUser.isAdmin();

    if (response.status == 200 && response.data.isAdmin) {
      return true;
    }
  }

  async submitForm(event) {
    console.log("submit 1");

    event.preventDefault();
    console.log("submit");
    const { t } = this.props;

    if (
      this.state.role === "NO_SUBSCRIBTION" ||
      this.state.formDatas?.disabled
    ) {
      return;
    }

    var serverResp = null;
    console.log(
      "this.state.formDatas.gameMode ",
      this.state.formDatas.gameMode
    );
    console.log(
      "this.state.formDatas.cameraBoundsNE.longitude ",
      this.state.formDatas.cameraBoundsNE.longitude
    );
    if (this.state.formDatas.gameMode === GameMode.MAP_AND_QRCODE) {
      if (
        !this.state.formDatas.homeCoords.longitude ||
        !this.state.formDatas.homeCoords.latitude
      ) {
        this.setState((prevState) => ({
          ...prevState,
          showError: true,
          errorMessage: t("newTour.no_coords"),
          showSuccess: false,
          successMessage: "",
        }));

        return;
      }
      if (
        !this.state.formDatas.cameraBoundsNE.longitude ||
        !this.state.formDatas.cameraBoundsNE.latitude
      ) {
        this.setState((prevState) => ({
          ...prevState,
          showError: true,
          errorMessage: t("newTour.no_ne_coords"),
          showSuccess: false,
          successMessage: "",
        }));

        return;
      }
      if (
        !this.state.formDatas.cameraBoundsSW.longitude ||
        !this.state.formDatas.cameraBoundsSW.latitude
      ) {
        this.setState((prevState) => ({
          ...prevState,
          showError: true,
          errorMessage: t("newTour.no_sw_coords"),
          showSuccess: false,
          successMessage: "",
        }));

        return;
      }
    }

    if (this.state.editing) {
      this.setState((prevState) => ({
        ...prevState,
        loadingViewOpened: true,
      }));
      if (
        this.state.formDatas.gameMode == GameMode.MAP_AND_QRCODE &&
        this.state.formDatas.annotationImageUrl == "" &&
        !this.state.formDatas.annotationImageFile
      ) {
        this.setState((prevState) => ({
          ...prevState,
          showError: true,
          loadingViewOpened: false,
          errorMessage: t("newTour.annotation_image_too_big"),
          showSuccess: false,
          successMessage: "",
        }));

        return;
      }
      serverResp = await ApiTour.updateTour(
        this.state.formDatas,
        this.state.formDatas.tourId
      );
      if (this.state.zonesOrderIdsChanged) {
        let res = await ApiZone.updateZonesOrdersIds(this.state.zonesDatas);
      }
    } else {
      if (this.state.formDatas.backgroundImageFile == null) {
        this.setState((prevState) => ({
          ...prevState,
          showError: true,
          errorMessage: t("newTour.background_image_missing"),
          showSuccess: false,
          successMessage: "",
        }));

        return;
      }

      if (
        this.state.formDatas.annotationImageFile == null &&
        this.state.formDatas.gameMode == GameMode.MAP_AND_QRCODE
      ) {
        this.setState((prevState) => ({
          ...prevState,
          showError: true,
          errorMessage: t("newTour.annotation_image_missing"),
          showSuccess: false,
          successMessage: "",
        }));

        return;
      }

      this.setState((prevState) => ({
        ...prevState,
        loadingViewOpened: true,
      }));
      let admin = await this.isAdmin();
      if (
        admin &&
        this.state.formDatas.clientUserId != null &&
        this.clientUserId != ""
      ) {
        serverResp = await ApiAdmin.uploadClientTour(this.state.formDatas);
      } else {
        serverResp = await ApiTour.uploadTour(this.state.formDatas);
      }
    }

    if (serverResp.status == 403) {
      this.setState((prevState) => ({
        ...prevState,
        showSuccess: false,
        showError: true,
        errorMessage: t("newTour.max_tours_reached"),
        dialogWaringOpened: false,
        loadingViewOpened: false,
      }));
    } else if (serverResp.status != 201 && serverResp.status != 200) {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: t("newTour.error_server_communication"),
        showSuccess: false,
        successMessage: "",
        loadingViewOpened: false,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        showSuccess: true,
        unsavedChanges: false,
        editing: true,
        successMessage: t("newTour.success_tour_saved"),
        showError: false,
        errorMessage: "",
        loadingViewOpened: false,
        formDatas: {
          ...prevState.formDatas,
          tourId: serverResp.data.tourId,
        },
      }));
    }
  }

  onDragEnd = ({ destination, source }) => {
    if (!destination) return;

    const zonesDatas = this.state.zonesDatas;

    let zoneSource = zonesDatas.find((zone, i) => {
      return i === source.index;
    });
    let zoneDest = zonesDatas.find((zone, i) => {
      return i === destination.index;
    });

    zonesDatas.splice(
      destination.index,
      0,
      zonesDatas.splice(source.index, 1)[0]
    );

    zonesDatas.forEach((zone, i) => {
      zone.orderId = i;
    });

    this.setState((prevState) => ({
      ...prevState,
      unsavedChanges: true,
      zonesOrderIdsChanged: true,
      zonesDatas: zonesDatas,
    }));
  };

  async deleteZone(zoneId) {
    const { t } = this.props;
    let res = await ApiZone.deleteZone(zoneId);

    if (res.status == 200) {
      let newZones = this.state.zonesDatas;
      newZones.forEach((zone, index) => {
        if (zone.id == zoneId) {
          newZones.splice(index, 1);
        }
      });
      this.setState((prevState) => ({
        ...prevState,
        showSuccess: true,
        successMessage: t("newTour.zone_deleted"),
        showError: false,
        dialogWaringOpened: false,
        zonesDatas: newZones,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: t("newTour.zone_delete_error"),
        showSuccess: false,
        successMessage: "",
        dialogWaringOpened: false,
      }));
    }
  }

  openDeleteZoneDialog(zoneId) {
    this.setState((prevState) => ({
      ...prevState,
      zoneIdToDelete: zoneId,
      dialogWaringOpened: true,
    }));
  }

  closeDeleteZoneDialog() {
    this.setState((prevState) => ({
      ...prevState,
      dialogWaringOpened: false,
    }));
  }

  getZonesItems() {
    const zonesItems = [];
    if (this.state.zonesDatas) {
      this.state.zonesDatas.forEach((zone) => {
        let icon = <LocationOnIcon />;
        if (zone.zoneMode == ZoneMode.QRCODE) {
          icon = <QrIcon />;
        }
        let item = {
          id: `zone-${zone.id}`,
          idInt: zone.id,
          primary: zone.name,
          secondary: "",
          to: `/zone?tourId=${this.state.formDatas.tourId}&zoneId=${zone.id}`,
          icon: icon,
          isValid: zone.isValid,
          isActive: zone.isActive,
        };

        zonesItems.push(item);
      });
    }
    return zonesItems;
  }

  render() {
    return (
      <TourView
        {...this.props}
        state={this.state}
        setSelectedTab={this.setSelectedTab}
        updateTitle={this.updateTitle}
        updateSubtitle={this.updateSubtitle}
        submitForm={this.submitForm}
        deleteMusic={this.deleteMusic}
        backgroundMusicSelected={this.backgroundMusicSelected}
        backgroundImageSelected={this.backgroundImageSelected}
        handleGameModeChange={this.handleGameModeChange}
        inGameBackgroundImageSelected={this.inGameBackgroundImageSelected}
        annotationImageSelected={this.annotationImageSelected}
        updateHomeCoordsLongitude={this.updateHomeCoordsLongitude}
        updateHomeCoordsLatitude={this.updateHomeCoordsLatitude}
        updateCameraBoundsNELongitude={this.updateCameraBoundsNELongitude}
        updateCameraBoundsNELatitude={this.updateCameraBoundsNELatitude}
        updateCameraBoundsSWLongitude={this.updateCameraBoundsSWLongitude}
        updateCameraBoundsSWLatitude={this.updateCameraBoundsSWLatitude}
        updateHomeAnnotationName={this.updateHomeAnnotationName}
        updateRedirectToHomeWhileFinished={
          this.updateRedirectToHomeWhileFinished
        }
        updateCluesFree={this.updateCluesFree}
        updateNeedDownloadMapTiles={this.updateNeedDownloadMapTiles}
        updateMustAddAnalyticBeforeLaunch={
          this.updateMustAddAnalyticBeforeLaunch
        }
        valueSliderTourTimer={this.valueSliderTourTimer}
        updateTimer={this.updateTimer}
        onDragEnd={this.onDragEnd}
        openDeleteZoneDialog={this.openDeleteZoneDialog}
        deleteZone={this.deleteZone}
        closeDeleteZoneDialog={this.closeDeleteZoneDialog}
        getZonesItems={this.getZonesItems}
        setHomeLocation={this.setHomeLocation}
        setCameraBoundsNELocation={this.setCameraBoundsNELocation}
        setCameraBoundsSWLocation={this.setCameraBoundsSWLocation}
        mapDialogOpen={this.state.mapDialogOpen}
        closeMapDialog={this.closeMapDialog}
        openMapDialog={this.openMapDialog}
        mapType={this.state.mapType}
        showLatOnMap={this.state.showLatOnMap}
        showLngOnMap={this.state.showLngOnMap}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withTranslation()(NewTour)
);
