import React from "react";
import * as moment from "moment";
import { Link } from "react-router-dom";
import {
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
  ListItemAvatar,
  Avatar,
  IconButton,
  Divider,
  Box,
  Typography,
  Grid,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import FileCopy from "@material-ui/icons/FileCopy";
import { Draggable } from "react-beautiful-dnd";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Tooltip from "@material-ui/core/Tooltip";
import { Edit } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  draggingListItem: {
    background: "#232C48",
  },
  listItem: {
    background: "#232C48",
    "&:hover": {
      backgroundColor: "#4B5679",
    },
  },
  listItemSelected: {
    background: "#4B5679",
    "&:hover": {
      backgroundColor: "#4B5679",
    },
  },
  deleteIcon: {
    color: "#DD7676",
    [theme.breakpoints.down("sm")]: {
      width: 40,
      height: 40,
    },
  },
  icon: {
    color: "#768FDD",
    [theme.breakpoints.down("sm")]: {
      width: 40,
      height: 40,
    },
  },
  iconAlert: {
    color: "#FF0000",
    [theme.breakpoints.down("sm")]: {},
  },
  warningIcon: {
    marginLeft: "20px",
    height: "100%",
  },
  listItemText: {
    color: "#FFFFFF",
  },
  listItemTextTwo: {
    color: "#FFFFFF",
  },
  hasAbo: {
    color: "green",
  },
  noAbo: {
    color: "red",
  },
  admin: {
    color: "white",
  },
  innactive: {
    backgroundColor: "#666975",
  },
  secondaryActions: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      width: 12,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 18,
    },
  },
  switchVisibility: {
    display: "inline",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const DraggableListItem = ({
  item,
  index,
  onDelete,
  onDuplicate,
  onEdit,
  onClick,
  selectedId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const CustomLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref) => (
        <Link ref={ref} to={item.to} {...linkProps} />
      )),
    [item.to]
  );

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={
            item.isActive === false
              ? classes.innactive
              : snapshot.isDragging
              ? classes.draggingListItem
              : selectedId !== undefined && selectedId === item.idInt
              ? classes.listItemSelected
              : classes.listItem
          }
          button
          component={onClick ? null : CustomLink}
          onClick={onClick ? () => onClick(item.idInt) : null}
        >
          <ListItemAvatar className={classes.switchVisibility}>
            <Avatar>{item.icon}</Avatar>
          </ListItemAvatar>
          <Grid container spacing={2}>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
              }}
              xs={!onEdit && !onEdit && !item.language ? 8 : 12}
              sm={8}
            >
              <Grid item xs={12} md={item.createdAt && item.language ? 3 : 6}>
                <ListItemText
                  primary={item.primary}
                  secondary={item.secondary}
                  classes={{
                    primary: classes.listItemText,
                    secondary: classes.listItemText,
                  }}
                />
              </Grid>
              {item.primaryTwo && item.secondaryTwo ? (
                <Grid item xs={12} md={3}>
                  <ListItemText
                    primary={item.primaryTwo}
                    secondary={item.secondaryTwo}
                    classes={{
                      primary: classes.listItemTextTwo,
                      secondary: classes.listItemTextTwo,
                    }}
                  />
                </Grid>
              ) : (
                <Grid item xs={0}></Grid>
              )}
              {item.role ? (
                <Grid item xs={12} md={3}>
                  <ListItemText
                    primary={
                      item.role === "PACK_DECOUVERTE"
                        ? "Pack découverte"
                        : item.role === "PACK_AVENTURE"
                        ? "Pack aventure"
                        : item.role === "PACK_EXPERT"
                        ? "Pack expert"
                        : item.role === "NO_SUBSCRIBTION"
                        ? "Pas de souscription"
                        : item.role === "NEW_MEMBER"
                        ? "Nouveau membre"
                        : item.role === "ADMIN"
                        ? "Admin"
                        : "ROLE INCONNUE"
                    }
                    secondary=""
                    classes={{
                      primary:
                        item.role === "NO_SUBSCRIBTION" ||
                        item.role === "NEW_MEMBER"
                          ? classes.noAbo
                          : item.role === "ADMIN"
                          ? classes.admin
                          : classes.hasAbo,
                      secondary: classes.listItemTextTwo,
                    }}
                    style={{ color: "blue" }}
                  />
                </Grid>
              ) : (
                <Grid item xs={0}></Grid>
              )}

              {item.createdAt && item.language ? (
                <Grid item xs={12} md={3}>
                  <ListItemText
                    primary={moment(item.createdAt).format("DD/MM/YYYY")}
                    secondary={item.language}
                    classes={{
                      primary: classes.listItemTextTwo,
                      secondary: classes.listItemTextTwo,
                    }}
                  />
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
            <Grid
              item
              xs={!onEdit && !onEdit && !item.language ? 4 : 12}
              sm={4}
            >
              <div className={classes.secondaryActions}>
                {!item.isValid ? (
                  <div>
                    <Tooltip
                      className={classes.warningIcon}
                      title={t("draggable_list_item.missing_data")}
                    >
                      <WarningRoundedIcon className={classes.iconAlert} />
                    </Tooltip>
                  </div>
                ) : (
                  <></>
                )}
                {onEdit ? (
                  <Tooltip title={t("draggable_list_item.edit")}>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      className={classes.icon}
                      onClick={(e) => {
                        onEdit(item.idInt);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <></>
                )}
                {/*  */}
                {onDuplicate ? (
                  <Tooltip title={t("draggable_list_item.duplicate")}>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      color="white"
                      className={classes.icon}
                      onClick={(e) => {
                        onDuplicate(item.idInt);
                      }}
                    >
                      <FileCopy />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <></>
                )}
                {onDelete ? (
                  <Tooltip title={t("draggable_list_item.delete")}>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      className={classes.deleteIcon}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onDelete(item.idInt);
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <></>
                )}
              </div>
            </Grid>
          </Grid>
        </ListItem>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
