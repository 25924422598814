import React, { useState } from "react";

import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TablePagination,
  Icon,
} from "@material-ui/core";
import { Search, Delete, Send, Cancel, BarChart } from "@material-ui/icons";
import { NewCodeDialog } from "./newCodeDialog/newCodeDialog";
import { useTranslation } from "react-i18next";

import "moment/locale/fr";

const CodeListView = ({
  state,
  role,
  tourCodes,
  classes,
  handleOpenAddCodeDialog,
  handleCloseAddCodeDialog,
  dialogOpen,
  tourId,
  handleSearchChange,
  formatDate,
  getStatut,
  getSentStatut,
  isMailSent,
  sendMail,
  searchTerm,
  archiveTourCode,
  formatDateShort,
  handleChangePage,
  handleChangeRowsPerPage,
  onOpenStats,
  tourDisabled,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {(!tourId || tourId === -1) && tourId !== 0 ? (
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Typography style={{ color: "white", fontWeight: 100 }}>
            {t("code_list.no_tour_selected")}
          </Typography>
        </Box>
      ) : (
        <>
          <Box display="flex" justifyContent="end" alignItems="center">
            {state.leaderboardActivated ? (
              <a
                edge="end"
                aria-label="edit"
                className={classes.icon}
                style={{
                  color: "white",
                  fontSize: "16px",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  // gap: "10px",
                }}
                href={"/statistiques?tourId=" + tourId}
                target="_blank"
              >
                <BarChart />
                {t("code_list.leaderboard")}
              </a>
            ) : (
              <></>
            )}
          </Box>
          {(tourDisabled || role === "NO_SUBSCRIBTION") && (
            <Typography className={classes.tourDeactivated}>
              {t("code_list.tour_disabled")}
            </Typography>
          )}
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              className={`${classes.addCodeButton} ${
                role === "NO_SUBSCRIBTION" || tourDisabled
                  ? classes.buttonDisabled
                  : ""
              }`}
              onClick={
                role === "NO_SUBSCRIBTION" || tourDisabled
                  ? null
                  : handleOpenAddCodeDialog
              }
            >
              {t("code_list.generate_code")}
            </Button>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <TextField
              label={t("code_list.search")}
              variant="outlined"
              value={searchTerm}
              size="small"
              onChange={handleSearchChange}
              className={classes.searchInput}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search className={classes.switchVisibility} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <TableContainer component={Paper} className={classes.table}>
            <Table sx={{ minWidth: 650 }} aria-label="">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCellHeader}>
                    {t("code_list.name")}
                  </TableCell>
                  <TableCell className={classes.tableCellHeader}>
                    {t("code_list.information")}
                  </TableCell>
                  <TableCell className={classes.tableCellHeader}>
                    {t("code_list.code")}
                  </TableCell>
                  <TableCell className={classes.tableCellHeader}>
                    {t("code_list.expiration_date")}
                  </TableCell>
                  <TableCell className={classes.tableCellHeader}>
                    {t("code_list.status")}
                  </TableCell>
                  <TableCell
                    className={classes.tableCellHeader}
                    style={{ width: 120 }}
                  ></TableCell>
                  <TableCell
                    className={classes.tableCellHeader}
                    style={{ width: 70 }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tourCodes?.map((tourCode) => (
                  <TableRow key={tourCode.id}>
                    <TableCell className={classes.tableCell}>
                      {tourCode.guestGameUser.teamName ? (
                        <div style={{ fontStyle: "italic" }}>
                          {tourCode.guestGameUser.teamName}
                          <br />
                        </div>
                      ) : (
                        <></>
                      )}
                      {tourCode.guestGameUser.firstname}{" "}
                      {tourCode.guestGameUser.lastname}
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#ffffffbb",
                        }}
                      >
                        {formatDate(tourCode.createdAt)}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {tourCode.isTest ? (
                        <div style={{ marginBottom: "2px" }}>
                          <div
                            style={{
                              border: "1px solid #768FDD",
                              borderRadius: "8px",
                              padding: "4px",
                              display: "inline-block",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "10px",
                                fontWeight: 900,
                                color: "#768FDD",
                              }}
                            >
                              TEST
                            </Typography>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {tourCode.isStoreGame == true ? (
                        <div style={{ marginBottom: "2px" }}>
                          <div
                            style={{
                              border: "1px solid #768FDD",
                              borderRadius: "8px",
                              padding: "4px",
                              display: "inline-block",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "10px",
                                fontWeight: 900,
                                color: "#768FDD",
                              }}
                            >
                              Store
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "10px",
                                fontWeight: 900,
                                color: "#768FDD",
                              }}
                            >
                              {tourCode.iosProductId == null &&
                              tourCode.androidProductId == null
                                ? "Gratuit"
                                : ""}
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "10px",
                                fontWeight: 900,
                                color: "#768FDD",
                              }}
                            >
                              {tourCode.iosProductId}
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "10px",
                                fontWeight: 900,
                                color: "#768FDD",
                              }}
                            >
                              {tourCode.androidProductId}
                            </Typography>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {tourCode.unlimitedUsages == true ? (
                        <div style={{ marginBottom: "2px" }}>
                          <div
                            style={{
                              border: "1px solid #768FDD",
                              borderRadius: "8px",
                              padding: "4px",
                              display: "inline-block",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "10px",
                                fontWeight: 900,
                                color: "#768FDD",
                              }}
                            >
                              Utilisations illimitées
                            </Typography>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {tourCode.maxNumberOfUsages > 1 ? (
                        <div style={{ marginBottom: "2px" }}>
                          <div
                            style={{
                              border: "1px solid #768FDD",
                              borderRadius: "8px",
                              padding: "4px",
                              display: "inline-block",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "10px",
                                fontWeight: 900,
                                color: "#768FDD",
                              }}
                            >
                              {tourCode.maxNumberOfUsages} utilisations max
                            </Typography>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {tourCode.usageLimitMinutes != null ? (
                        <div style={{ marginBottom: "2px" }}>
                          <div
                            style={{
                              border: "1px solid #768FDD",
                              borderRadius: "8px",
                              padding: "4px",
                              display: "inline-block",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "10px",
                                fontWeight: 900,
                                color: "#768FDD",
                              }}
                            >
                              Expire {tourCode.usageLimitMinutes / 60}h après
                              activation
                            </Typography>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <Typography
                        style={{
                          fontSize: "14px",
                          color: "#ffffffdd",
                        }}
                      >
                        {tourCode.guestGameUser.email
                          ? tourCode.guestGameUser.email
                          : "Aucun email"}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#ffffffbb",
                        }}
                      >
                        {tourCode.guestGameUser.phoneNumber
                          ? tourCode.guestGameUser.phoneNumber
                          : "Aucun téléphone"}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {tourCode.isStoreGame ? "" : tourCode.code}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {formatDate(tourCode.endDate)}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {getStatut(
                        tourCode.endDate,
                        tourCode.usageDate,
                        tourCode.isTest
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {isMailSent(tourCode) ? (
                        <>
                          <Box className={classes.alreadySentMailButton}>
                            <Typography
                              style={{
                                fontSize: "12px",
                                fontWeight: 800,
                                textAlign: "center",
                              }}
                            >
                              {t("code_list.sent_on")}
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "12px",
                                fontWeight: 500,
                                textAlign: "center",
                              }}
                            >
                              {formatDateShort(tourCode.sentAt)}
                            </Typography>
                          </Box>
                          <Box style={{ marginTop: 6 }}>
                            <Button
                              fullWidth
                              className={
                                tourCode.guestGameUser.email
                                  ? classes.sendMailButton
                                  : classes.disabledSendMailButton
                              }
                              disabled={!tourCode.guestGameUser.email}
                              onClick={() => sendMail(tourCode.id)}
                            >
                              <Typography
                                style={{ fontSize: "12px", fontWeight: 800 }}
                              >
                                {t("code_list.resend")}
                              </Typography>
                            </Button>
                          </Box>
                        </>
                      ) : (
                        <Box>
                          <Button
                            fullWidth
                            className={`${
                              tourCode.guestGameUser.email
                                ? classes.sendMailButton
                                : classes.disabledSendMailButton
                            } ${
                              role === "NO_SUBSCRIBTION" || tourDisabled
                                ? classes.buttonDisabled
                                : ""
                            }`}
                            disabled={!tourCode.guestGameUser.email}
                            onClick={() => {
                              role === "NO_SUBSCRIBTION" || tourDisabled
                                ? {}
                                : sendMail(tourCode.id);
                            }}
                          >
                            <Typography
                              style={{ fontSize: "12px", fontWeight: 800 }}
                            >
                              {t("code_list.send")}
                            </Typography>
                          </Button>
                        </Box>
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {!tourCode.isStoreGame ? (
                        <>
                          <IconButton
                            style={{
                              color:
                                role === "NO_SUBSCRIBTION" || tourDisabled
                                  ? "#8e999d"
                                  : "#DD7676",
                            }}
                            onClick={() => {
                              role === "NO_SUBSCRIBTION" || tourDisabled
                                ? {}
                                : archiveTourCode(tourCode.id);
                            }}
                          >
                            <Cancel />
                          </IconButton>
                        </>
                      ) : (
                        <></>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              style={{ color: "white" }}
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={state.totalRows}
              rowsPerPage={state.rowPerPage}
              page={state.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t("code_list.rows_per_page")}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} ${t("code_list.of")} ${count}`
              }
            />
          </TableContainer>
          <NewCodeDialog
            open={dialogOpen}
            handleClose={handleCloseAddCodeDialog}
            tourId={tourId}
          ></NewCodeDialog>
        </>
      )}
    </>
  );
};

export default CodeListView;
