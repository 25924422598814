import React from "react";
import {
  AppBar,
  Box,
  Card,
  Typography,
  Grid,
  Checkbox,
  Button,
  Slider,
  Switch,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import {
  CheckCircle,
  CheckCircleOutline,
  RadioButtonUnchecked,
} from "@material-ui/icons";
import MainAppBar from "../../components/MainAppBar";
import DialogWarning from "../../components/dialogWarning";
import NoSubscriptionBanner from "../home/components/noSubscriptionBanner";
import FloatingVideoLink from "../../components/floatingVideoLinks";
import { useTranslation } from "react-i18next";

const SubscriptionView = (props) => {
  const { t } = useTranslation();
  const {
    showLoadingView,
    role,
    isMonthly,
    classes,
    subscriptions,
    subscriptionsMonth,
    handleCheckboxChange,
    handleSubscriptionSelect,
    userLanguage,
  } = props;
  const subscriptionEntries = Object.entries(subscriptions);
  console.log("subscriptionEntries ", subscriptionEntries);

  return (
    <>
      <MainAppBar headerText={t("subscription.tours")} showHomeButton={true} />
      <Box className={classes.container}>
        <Typography
          className={classes.title}
          style={{ fontWeight: "bold", fontSize: "32px" }}
        >
          {t("subscription.choose_plan")}
        </Typography>
        {/* <FloatingVideoLink role={role}></FloatingVideoLink> */}
        <Box display="flex" justifyContent="center" marginBottom={2}>
          <Typography style={{ fontWeight: "bold" }}>
            {t("subscription.monthly")}
          </Typography>
          <Switch
            checked={!isMonthly}
            onChange={handleCheckboxChange}
            // color="primary"
            inputProps={{ "aria-label": t("subscription.switch_aria") }}
          />

          <Typography style={{ fontWeight: "bold" }}>
            {t("subscription.six_months")}
          </Typography>
          <Typography className={classes.badge}>-25%</Typography>
        </Box>
        <Grid container spacing={2} justifyContent="center">
          {showLoadingView ? (
            // Affiche un loader si subscriptionEntries est null
            <CircularProgress />
          ) : (
            subscriptionEntries
              .filter(([key, sub]) => sub)
              .map(([key, sub], index) => (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={index}
                    style={{ maxWidth: "350px" }}
                  >
                    <Card
                      className={`${classes.subscriptionCard} ${
                        sub.priceId === props.priceId ||
                        sub.priceIdEnglish === props.priceId
                          ? "selectedPack"
                          : ""
                      }`}
                    >
                      <Box className={classes.columnHeader}>
                        <Typography
                          variant="h4"
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontStyle: "italic",
                          }}
                        >
                          {sub.displayName == "Pack découverte"
                            ? t("subscription.pack_decouverte")
                            : sub.displayName == "Pack aventure"
                            ? t("subscription.pack_aventure")
                            : t("subscription.pack_expert")}
                        </Typography>
                        <Typography variant="h6" style={{ color: "white" }}>
                          {sub.month ? t("subscription.no_commitment") : ""}
                        </Typography>
                      </Box>
                      <Box className={classes.rowsContainer}>
                        {!isMonthly ? (
                          <Box className={classes.containerPricesDiff}>
                            <Typography className={classes.priceCrossed}>
                              {sub.priceId ===
                                "price_1Px5LfCPk14nMN0Xxo32l7cV" ||
                              sub.priceId === "price_1QVX8KCPk14nMN0X7wcUrIeo"
                                ? `${subscriptionsMonth.decouverte.price * 6}€`
                                : sub.priceId ===
                                    "price_1Px5LkCPk14nMN0Xphhsmw5e" ||
                                  sub.priceId ===
                                    "price_1QVX8GCPk14nMN0X1Wm83stE"
                                ? `${subscriptionsMonth.aventure.price * 6}€`
                                : `${subscriptionsMonth.expert.price * 6}€`}
                            </Typography>
                            <Typography className={classes.price}>
                              {sub.price}€
                            </Typography>
                          </Box>
                        ) : (
                          <Typography className={classes.price}>
                            {sub.price}€
                          </Typography>
                        )}

                        {isMonthly ? (
                          <Typography className={classes.periodic}>
                            {t("subscription.monthly_period")}
                          </Typography>
                        ) : (
                          <Typography className={classes.periodic}>
                            {t("subscription.six_month_period")}
                          </Typography>
                        )}

                        <Typography
                          style={{ marginTop: 20 }}
                          className={classes.row}
                        >
                          <CheckCircleOutline style={{ marginRight: 6 }} />
                          {t("subscription.creation_studio")}
                        </Typography>

                        <Divider className={classes.rowDivider} />

                        <Typography className={classes.row}>
                          <CheckCircleOutline style={{ marginRight: 6 }} />
                          {sub.maxTours}{" "}
                          {sub.maxTours === 1
                            ? t("subscription.tour")
                            : t("subscription.tours")}
                        </Typography>
                        <Divider className={classes.rowDivider} />
                        <Typography className={classes.row}>
                          <CheckCircleOutline style={{ marginRight: 6 }} />
                          {sub.maxZones} {t("subscription.zones")}
                        </Typography>
                        <Divider className={classes.rowDivider} />
                        <Typography className={classes.row}>
                          <CheckCircleOutline style={{ marginRight: 6 }} />
                          {sub.maxEnigmas} {t("subscription.enigmas")}
                        </Typography>
                        <Divider className={classes.rowDivider} />

                        <Typography className={classes.row}>
                          <CheckCircleOutline style={{ marginRight: 6 }} />
                          {sub.maxSessionsPerMonth}{" "}
                          {t("subscription.free_sessions")}
                        </Typography>
                        <Divider className={classes.rowDivider} />

                        <Typography className={classes.row}>
                          <CheckCircleOutline style={{ marginRight: 6 }} />
                          {t("subscription.online_help")}
                        </Typography>
                        <Divider className={classes.rowDivider} />

                        <Typography className={classes.row}>
                          {sub.hasLeaderboard ? (
                            <CheckCircleOutline style={{ marginRight: 6 }} />
                          ) : (
                            <RadioButtonUnchecked
                              style={{ color: "orange", marginRight: 6 }}
                            />
                          )}{" "}
                          {t("subscription.leaderboard")}
                        </Typography>
                        {/* <Divider className={classes.rowDivider} />

                        <Typography className={classes.row}>
                          {sub.tourOffered ? (
                            <CheckCircleOutline style={{ marginRight: 6 }} />
                          ) : (
                            <RadioButtonUnchecked
                              style={{ color: "orange", marginRight: 6 }}
                            />
                          )}{" "}
                          {t("subscription.tour_offered")}
                        </Typography> */}

                        <Divider className={classes.rowDivider} />

                        <Typography className={classes.row}>
                          {sub.trainingOffered ? (
                            <CheckCircleOutline style={{ marginRight: 6 }} />
                          ) : (
                            <RadioButtonUnchecked
                              style={{ color: "orange", marginRight: 6 }}
                            />
                          )}{" "}
                          {t("subscription.training_offered")}
                        </Typography>
                      </Box>

                      <Typography className={classes.ht} variant="body2">
                        {t("subscription.price_ht")}
                      </Typography>

                      {sub.priceId === props.priceId ||
                      sub.priceIdEnglish === props.priceId ? (
                        <Button
                          className={classes.subscribeButton}
                          style={{
                            backgroundColor: "grey",
                            cursor: "default",
                          }}
                          variant="contained"
                          color="primary"
                          onClick={() => {}}
                        >
                          {t("subscription.subscribe")}
                        </Button>
                      ) : (
                        <Button
                          className={classes.subscribeButton}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            if (props.priceId) {
                              // Un abonnement est en cours
                              props.openDialog(key);
                            } else {
                              // Aucun abonnement en cours
                              handleSubscriptionSelect(key);
                            }
                          }}
                        >
                          {t("subscription.subscribe")}
                        </Button>
                      )}

                      {props.hasTrial && !props.priceId ? (
                        <Typography
                          style={{ marginTop: 20 }}
                          className={classes.weekFreeText}
                        >
                          {t("subscription.week_free")}
                        </Typography>
                      ) : (
                        <div style={{ height: 20 }}></div>
                      )}
                    </Card>
                  </Grid>
                  <DialogWarning
                    open={props.dialogWaringOpened}
                    primaryText={t("subscription.change_subscription_warning")}
                    secondaryText={t(
                      "subscription.change_subscription_prorata"
                    )}
                    primaryAction={() => props.handleSubscriptionSelect(null)}
                    closeAction={props.closeDialog}
                    primaryButtonText={t("subscription.validate")}
                    secondaryButtonText={t("subscription.cancel")}
                  />
                </>
              ))
          )}
        </Grid>
        <Grid container justifyContent="center">
          <Typography className={classes.info}>
            * {t("subscription.free_sessions_infos")}
          </Typography>
        </Grid>
      </Box>
    </>
  );
};

export default SubscriptionView;
